import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { LayoutBreakpointService } from '@headpower/layout';
import { EmptyError, Subject, takeUntil } from 'rxjs';

import { ParagraphComponent } from '../../../models/paragraph-component/paragraph-component';
import { ResourceEntry, ResourceService } from 'src/app/modules/shared/services/resource.service';

@Component({
    selector: 'app-text-and-image-paragraph',
    templateUrl: './text-and-image-paragraph.component.html',
    styleUrls: ['./text-and-image-paragraph.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TextAndImageParagraphComponent implements OnInit, OnDestroy, ParagraphComponent {

    @Input() public data: any;

    public mobile: boolean = false;
    public loading: boolean = true;

    public source: SafeResourceUrl;
    public caption: string;
    public sanitizedHtml: SafeHtml;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private lightbox: Lightbox,
        private layoutBreakpointService: LayoutBreakpointService,
        private sanitizer: DomSanitizer,
        private resourceService: ResourceService) { }

    ngOnInit() {
        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                this.mobile = result.handset;
            });

        let operations: Promise<void>[] = [];

        // Image
        operations.push(this.setSourceFromImage());

        // Text
        const processedFieldText = this.data.self.attributes.field_text_and_image_text.processed;
        const doc = new DOMParser().parseFromString(processedFieldText, 'text/html');

        this.setLinkTargets(doc);

        // Create align divs for images
        this.wrapDiv(doc, 'align-left');
        this.wrapDiv(doc, 'align-center');
        this.wrapDiv(doc, 'align-right');

        operations.push(this.buildImages(doc));

        Promise.allSettled(operations)
            .then(() => {
                this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(doc.documentElement.innerHTML);
                this.loading = false;
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public openLightbox() {
        const album: IAlbum = {
            src: this.source as any,
            thumb: null,
            caption: this.caption
        };

        this.lightbox.open([album], 0, {
            centerVertically: true
        });
    }

    private async setSourceFromImage() {
        const field = this.data.full.includedMap.get(this.data.self.relationships.field_text_and_image_image.data.id);

        if (!field) {
            return;
        }

        const file = this.data.full.includedMap.get(field.relationships.field_media_image.data.id);

        if (!file) {
            return;
        }

        this.caption = this.data.self.attributes.field_text_and_image_caption;

        const imageUrl = file.attributes.uri.url as string;

        if (this.resourceService.isPrivateResource(imageUrl)) {
            let resource: ResourceEntry;

            try {
                resource = await this.resourceService.getResource('image', imageUrl, file.attributes.filename);
            }
            catch (error) {
                // EmptyError is thrown if service disposes the resources while request is in progress.
                //
                // This happens when user navigates away from the instruction while downloading,
                // so just ignore the error and return.
                if (error instanceof EmptyError) {
                    return;
                }

                // Otherwise ignore error and continue execution
            }

            const url = resource?.objectUrl ?? '';

            this.source = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
        // Public resource, no access token header needed
        else {
            const url = this.resourceService.buildResourceUrl('image', imageUrl);

            this.source = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    }

    private setLinkTargets(doc: Document) {
        const links = doc.querySelectorAll('a');

        links.forEach(link => {
            if (!link.target) {
                link.setAttribute('target', '_blank');
            }
        });
    }

    /**
     * Creates a parent div for a figure that includes a image.
     * This allows the usage of aligning images using inline-block and text-align.
     * @param doc current html
     * @param className where the image should be aligned to
     */
    private wrapDiv(doc: Document, className: string) {
        const elems = doc.querySelectorAll(`.${className}`);

        elems.forEach(elem => {
            const div = doc.createElement('div');
            div.classList.add(className);

            elem.parentElement.replaceChild(div, elem);
            div.appendChild(elem);
        });
    }

    private async buildImages(doc: Document) {
        const imgElems = doc.querySelectorAll('img');

        if (imgElems.length === 0) {
            return;
        }

        let operations: Promise<void>[] = [];

        imgElems.forEach(elem => {
            operations.push(this.fetchImage(elem));
        });

        await Promise.allSettled(operations);
    }

    private async fetchImage(elem: HTMLImageElement) {
        if (this.resourceService.isPrivateResource(elem.src)) {
            let resource: ResourceEntry;

            try {
                resource = await this.resourceService.getResource('image', elem.src);
            }
            catch (error) {
                // EmptyError is thrown if service disposes the resources while request is in progress.
                //
                // This happens when user navigates away from the instruction while downloading,
                // so just ignore the error and return.
                if (error instanceof EmptyError) {
                    return;
                }

                // Otherwise ignore error and continue execution
            }

            const url = resource?.objectUrl ?? '';

            // FYI: No need to sanitize url here as we are modifying element directly
            elem.src = url;
        }
        // Public resource, no access token header needed
        else {
            elem.src = this.resourceService.buildResourceUrl('image', elem.src);
        }
    }
}
