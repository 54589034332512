import { ResponseNode } from '../../core/models/response-node.model';
import { Deserializable } from './deserializable.model';

export class PermissionCategory implements Deserializable {
  id: string;
  attributes: {
    name: string;
    product_id: string;
  };

  deserialize(input: object, included?: Map<string, ResponseNode>): this {
    return Object.assign(this, input);
  }
}
