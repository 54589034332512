import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Changelog } from '../../models/changelog';

export interface ChangelogDialogDataInterface {
    changelogEntries: Changelog[];
    mobile: boolean;
}

@Component({
    selector: 'app-changelog-dialog',
    templateUrl: './changelog-dialog.component.html',
    styleUrls: ['./changelog-dialog.component.scss'],
})
export class ChangelogDialogComponent implements OnInit {
    public changelogEntries: Changelog[];
    public mobile: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ChangelogDialogDataInterface,
    ) {
        this.changelogEntries = data.changelogEntries;
        this.mobile = data.mobile;
    }

    ngOnInit(): void { }
}
