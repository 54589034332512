<div class="text-and-image-paragraph"
    [ngClass]="{ 'desktop': !mobile, 'mobile': mobile }">

    <hpo-placeholder *ngIf="loading"
        type="row"></hpo-placeholder>

    <ng-container *ngIf="!loading">

        <div class="text-and-image-paragraph__container">

            <ng-container *ngIf="mobile">

                <a [href]="source"
                    target="_blank"
                    class="text-and-image-paragraph__link"
                    [class.text-and-image-paragraph__link--reversed]="data.self.attributes.field_text_and_image_reverse">
                    <figure>
                        <img [src]="source"
                            alt="Image"
                            class="text-and-image-paragraph__image">
                        <figcaption *ngIf="caption">
                            {{caption}}
                        </figcaption>
                    </figure>
                </a>

            </ng-container>

            <ng-container *ngIf="!mobile">

                <div class="text-and-image-paragraph__link"
                    [class.text-and-image-paragraph__link--reversed]="data.self.attributes.field_text_and_image_reverse">
                    <figure>
                        <img [src]="source"
                            alt="Image"
                            class="text-and-image-paragraph__image"
                            (click)="openLightbox()">
                        <figcaption *ngIf="caption">
                            {{caption}}
                        </figcaption>
                    </figure>
                </div>

            </ng-container>

        </div>

        <div [innerHTML]="sanitizedHtml"
            class="text-and-image-paragraph__content"
            [class.reversed]="data.self.attributes.field_text_and_image_reverse"></div>

    </ng-container>

</div>