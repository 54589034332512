<div class="result-list"
  *ngIf="tag">
  <h1 class="result-list__title">Aiheeseen "{{tag.attributes.name}}" liittyvät ohjeet</h1>

  <div class="result-list__container"
    *ngIf="instructions">
    <a class="result-item"
      routerLink="{{ instruction.attributes.path.alias }}"
      *ngFor="let instruction of instructions">
      <ng-template [ngIf]="instruction.categories.icon"
        [ngIfElse]="backupIcon">
        <img [src]="instruction.categories.icon.url+token"
          alt="Instruction icon"
          class="result-item__image">
      </ng-template>

      <div class="result-item__title">
        {{instruction.attributes.name}}
      </div>
    </a>
  </div>
</div>

<ng-template #backupIcon>
  <mat-icon class="result-item__icon">article</mat-icon>
</ng-template>