import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-catalog-inline-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() mobile = false;
  @Input() keyword = '';
  @Output() handleSearch: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSearch(keyword: string) {
    this.keyword = keyword;
    this.handleSearch.emit(this.keyword);
  }
}
