<hpo-content-layout [mobile]="mobile"
    [navigation]="false">

    <div class="content-container"
        [class.mobile]="mobile">

        <ng-container *ngIf="!initializing">

            <h1 class="title">{{ 'default.file' | blah }}</h1>

            <div class="file-container">

                <p>{{fileName}}</p>

                <div class="button-container">

                    <button mat-flat-button
                        color="primary"
                        [disabled]="loadingFile"
                        (click)="downloadFile()">
                        {{ 'default.downloadFile' | blah }}
                    </button>

                    <mat-progress-bar *ngIf="loadingFile"
                        mode="indeterminate"></mat-progress-bar>

                </div>

                <a mat-flat-button
                    [routerLink]="'/' + alias">
                    {{ 'default.backToInstruction' | blah }}
                </a>

            </div>

        </ng-container>

        <ng-container *ngIf="initializing">
            <hpo-placeholder type="row"></hpo-placeholder>
            <hpo-placeholder type="row"></hpo-placeholder>
            <hpo-placeholder type="row"></hpo-placeholder>
        </ng-container>

        <app-copyright></app-copyright>

    </div>

</hpo-content-layout>