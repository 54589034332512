import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterItem } from './filter-item';

@Component({
  selector: 'app-catalog-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() stacked = false;
  @Input() items: FilterItem[] = [];

  @Output() emitItemSelect: EventEmitter<FilterItem> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public selectItem(item: FilterItem) {
    this.emitItemSelect.emit(item);
  }
}
