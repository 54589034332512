import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog-search',
  templateUrl: './catalog-search.component.html',
  styleUrls: ['./catalog-search.component.scss'],
})
export class CatalogSearchComponent implements OnInit {
  searchActive = false;

  constructor() {}

  ngOnInit(): void {}

  public setSearchActive(active: boolean) {
    this.searchActive = active;
  }
}
