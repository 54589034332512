import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient } from '@angular/common/http';

export class UsageStatistics {
    UserId: string;
    CompanyId: string;
    Page: string;
    RemoteAddress: string;
}

export class ApplicationEvent {
    EventName: string;
    EventTarget: string;
    EventInfo: string;
}

@Injectable({
    providedIn: 'root'
})
export class AppService {

    // TODO: Implement Blah support. But since all instructions are written in Finnish
    // as of now, there really is no need.
    public title: BehaviorSubject<string> = new BehaviorSubject('HeadPower Ohjeistot');

    private statisticsUrl = `${environment.backendBaseUri}Portal.BackEnd/WebAPI/api/UsageStatistics/Post?apiKey=`;
    private instructionLogUrl = `${environment.backendBaseUri}Portal.BackEnd/Log.WebAPI/api/Log/LogApplicationEvent`;
    private statisticsSent = false;
    private logSent = false;

    constructor(
        private titleService: Title,
        private oAuthService: OAuthService,
        private http: HttpClient,
    ) {
        this.title.subscribe((title: string) => {
            this.titleService.setTitle(title);
        });
    }

    /**
     * Set the app title
     * @param title New title to display
     */
    public setAppTitle(title: string): void {
        if (!!title) {
            this.title.next(`HeadPower Ohjeistot | ${title}`);
        } else {
            this.title.next(`HeadPower Ohjeistot`);
        }
    }

    /**
     * Usage statistics for the app
     * @param page Current page
     */
    public sendPageUsageStatistics(page: string): Observable<any> {
        if (!this.statisticsSent) {
            const claims: any = this.oAuthService.getIdentityClaims();

            if (!claims) {
                return EMPTY;
            }

            const usageStatistics: UsageStatistics = {
                UserId: claims.sub,
                CompanyId: claims['headpower:company_uuid'],
                Page: page,
                RemoteAddress: ''
            };

            this.statisticsSent = true;

            return this.http.post(`${this.statisticsUrl}${environment.productApiKey}`, usageStatistics);
        } else {
            return EMPTY;
        }
    }

    /**
     * Log instruction opening
     * @param instructionId Which instruction was opened
     */
    public sendInstructionOpeningStatistics(instructionId: string): Observable<any> {
        if (!this.logSent) {
            const claims: any = this.oAuthService.getIdentityClaims();

            if (!claims) {
                return EMPTY;
            }

            const applicationEvent: ApplicationEvent = {
                EventName: 'InstructionOpen',
                EventTarget: instructionId.substring(1),
                EventInfo: claims['headpower:company_uuid']
            };

            this.logSent = true;

            return this.http.post(`${this.instructionLogUrl}`, applicationEvent);
        } else {
            return EMPTY;
        }
    }
}
