<div class="headpower-layout">
    <div class="headpower-catalog">
        <ng-template [ngIf]="!mobile && firstLoadingDone">
            <hpo-card-content class="supplier-products">
                <div>
                    <ng-container *ngIf="resultCount">
                        <app-catalog-tag-list [tags]="tags"
                            [tagGroups]="tagGroups"
                            [selectedTags]="selectedTags"
                            [selectableTags]="selectableTags"
                            (emitSelectedTags)="updateSelectedTags($event)">
                        </app-catalog-tag-list>
                    </ng-container>
                </div>
                <div *ngIf="hasCompanysInstructions"
                    class="supplier-products__container">
                    <div class="supplier-products-title">{{ 'default.companysInstructions' | blah }}:</div>
                    <app-product-list [GroupPriority]="localGroupPriority.SUPPLIER"
                        [products]="products"
                        [stacked]="true"
                        [catalogView]="true"
                        [selectedProductId]="selectedProductId"
                        (emitProductSelect)="selectProduct($event)">
                    </app-product-list>
                </div>
            </hpo-card-content>
        </ng-template>

        <hpo-card-content class="headpower-items">
            <hpo-placeholder *ngIf="!firstLoadingDone"
                type="row"></hpo-placeholder>
            <app-catalog-inline-search *ngIf="firstLoadingDone"
                [keyword]="keyword"
                (handleSearch)="onKeywordChange($event)">
            </app-catalog-inline-search>

            <mat-chip-list *ngIf="mobile && firstLoadingDone"
                class="mobile_taglist">
                <mat-chip *ngIf="selectedProductId"
                    (removed)="removeTag(undefined, 'product')">
                    {{productName}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
                <mat-chip *ngFor="let tag of selectedTags"
                    (removed)="removeTag(tag, 'tag')">
                    {{tag.attributes.name}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-list>

            <app-catalog-content (emitTagGroups)="updateTagGroups($event)"
                (emitTags)="updateTags($event)"
                (emitSelectableTags)="updateSelectableTags($event)"
                (emitResultCount)="updateResultCount($event)"
                [keyword]="keyword"
                [selectedProductId]="selectedProductId"
                [selectedTags]="selectedTags"
                [products]="products"
                [prefix]="PREFIX"
                [productName]="productName"
                [changelog]="true"
                [mobile]="mobile">
            </app-catalog-content>
        </hpo-card-content>
    </div>
</div>

<ng-template #noInstructions>
    <hpo-placeholder type="row"></hpo-placeholder>
    <hpo-placeholder type="row"></hpo-placeholder>
    <hpo-placeholder type="row"></hpo-placeholder>
</ng-template>

<hpo-bottom-nav *ngIf="mobile">
    <hpo-nav-item icon="list"
        (click)="mobileProducts()">
        {{ 'default.companysInstructions' | blah }}
    </hpo-nav-item>
    <hpo-nav-item icon="filter_list"
        (click)="mobileTags()">
        {{ 'default.keywords' | blah }}
    </hpo-nav-item>
</hpo-bottom-nav>