<div class="grid">
    <div class="character">
        <img src="assets/images/{{characterName}}.svg"
            alt="{{characterName}}"
            [ngStyle]="{
        width: mobile
          ? characterName == 'hemmo'
            ? '140px'
            : '200px'
          : characterName == 'hemmo'
          ? '180px'
          : '240px'
      }" />
    </div>
    <div class="information">
        <div>
            <p class="mat-title"
                *ngIf="mobile">
                {{ 'error.http.pageNotFound' | blah }}
            </p>
            <h3 *ngIf="!mobile">{{ 'error.http.pageNotFound' | blah }}</h3>
        </div>
        <div class="actions">
            <div class="input"
                *ngIf="!mobile">
                <p [ngClass]="{
            'mat-body-2': mobile,
            'mat-title': !mobile
          }">
                    {{ 'error.http.tryToSearchInstead' | blah }}
                </p>
                <app-add-search [active]="searchActive"
                    (activate)="setSearchActive()"></app-add-search>
            </div>
            <div class="browse-instructions">
                <button mat-flat-button
                    id="browse-instructions-button"
                    color="primary"
                    (click)="browseInstructions()">
                    Selaa ohjeita
                </button>
            </div>
        </div>
    </div>
</div>