import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-sitemap',
    templateUrl: './sitemap.component.html'
})
export class SitemapComponent implements OnInit {

    constructor(
        private oAuthService: OAuthService,
        private http: HttpClient) { }

    ngOnInit() {
        this.http.get(`${environment.instructionApiBaseUri}sitemap.xml/generate`, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
            },
            withCredentials: true
        })
            .subscribe(response => {
                window.location.replace(URL.createObjectURL(response));
            });
    }
}
