import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { InstructionsForTagComponent } from './components/instructions-for-tag/instructions-for-tag.component';

@NgModule({
  declarations: [
    InstructionsForTagComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class TagModule {
}
