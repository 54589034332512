<div *ngIf="errors.length > 0"
    id="login-error"
    class="error-container">

    <h4>{{ 'error.login.title' | blah }}</h4>

    <p class="error-info">
        {{ 'error.login.info1' | blah }}
        <br>
        {{ 'error.login.info2' | blah }}
    </p>

    <div id="login-error-details"
        class="error-details">

        <div *ngFor="let error of errors">
            {{error}}
        </div>

    </div>

</div>