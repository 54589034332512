import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { LayoutBreakpointService } from '@headpower/layout';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { GroupPriority, PortalProduct } from 'src/app/modules/core/services/portal/portal-product';
import { PortalService } from 'src/app/modules/core/services/portal/portal.service';
import { ChangelogService } from 'src/app/modules/shared/services/changelog.service';
import { ProductDialogComponent, ProductDialogDataInterface } from '../product-dialog/product-dialog.component';
import { FullScreenDialogComponent, FullScreenDialogService } from '@headpower/components';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-changelog',
    templateUrl: './changelog.component.html',
    styleUrls: ['./changelog.component.scss'],
})

export class ChangelogComponent implements OnInit, OnDestroy {
    public changelogEntries = {};
    public changelogView = [];
    public changelogSubscription: Subscription;
    public loading: boolean = true;
    public isChangelogPage: boolean = true;
    public products: PortalProduct[] = [];
    private destroy$: Subject<void> = new Subject();
    public selectedProductId = 0;
    public productName: string = '';
    public firstLoadingDone: boolean = false;
    public localGroupPriority = GroupPriority;
    public nextLinks = {};

    length = 0;
    pageSize = 10;
    pageIndex = 0;
    pageEvent: PageEvent;
    pageStart = 1;
    pageEnd = 10;

    @Input() changelogEntriesInput = [];
    @Input() mobile;

    productDialogRef: MatDialogRef<FullScreenDialogComponent>;

    constructor(
        private changelogService: ChangelogService,
        private portalService: PortalService,
        private layoutBreakpointService: LayoutBreakpointService,
        private fsDialogService: FullScreenDialogService
    ) {
        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.mobile = result.handset);
    }

    ngOnInit() {
        if (!this.changelogEntriesInput.length) {
            this.fetchProducts();
            this.getChangelogEntries();
        } else {
            this.changelogEntries[0] = this.changelogEntriesInput;
            this.length = this.changelogEntriesInput.length;
            this.isChangelogPage = false;
            this.getChangelogView();
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getChangelogEntries() {
        if (this.changelogSubscription) {
            this.changelogSubscription.unsubscribe();
        }

        if (this.changelogEntries[this.selectedProductId]) {
            this.getChangelogView();
        } else {
            this.loading = true;
            this.changelogEntries[this.selectedProductId] = [];

            if (this.selectedProductId) {
                this.changelogSubscription = this.changelogService
                    .getChangelogEntries(undefined, (this.selectedProductId && this.selectedProductId.toString()) || undefined)
                    .subscribe((changelogEntries: any) => {
                        this.changelogEntries[this.selectedProductId] = changelogEntries.data;
                        this.nextLinks[this.selectedProductId] = changelogEntries.links.next;
                        this.length = this.changelogEntries[this.selectedProductId].length;
                        this.getChangelogView();
                    });
            } else {
                this.getChangelogView();
            }

        }
    }

    public getMoreChangelogEntries() {
        if (this.length === this.pageEnd) {
            this.loading = true;
            this.changelogService.getChangelogEntriesByUrl(this.nextLinks[this.selectedProductId].href).subscribe(res => {
                this.changelogEntries[this.selectedProductId] = [...this.changelogEntries[this.selectedProductId], ...res.data];
                this.nextLinks[this.selectedProductId] = res.links.next;
                this.handlePageEvent('next');
            })
        }
    }

    public handlePageEvent(type) {
        this.length = this.changelogEntries[this.selectedProductId].length;

        if (type === 'next') {
            this.pageIndex++
        } else if (type === 'previous') {
            this.pageIndex--
        }

        window.scrollTo(0, 0);
        this.getChangelogView();
    }

    updatePagination() {
        this.pageStart = this.pageSize * this.pageIndex + 1;
        this.pageEnd = this.pageSize * (this.pageIndex + 1);
        this.pageEnd = this.pageEnd < this.length ? this.pageEnd : this.length;
    }

    private getChangelogView() {
        this.length = this.changelogEntries[this.selectedProductId].length;
        this.updatePagination();
        const end = (this.pageIndex + 1) * this.pageSize;
        const start = this.pageIndex * this.pageSize;
        const part = this.changelogEntries[this.selectedProductId].slice(start, end);
        this.changelogView = part;
        if (this.selectedProductId !== 0 || !this.isChangelogPage) {
            this.loading = false;
        }
    }

    private fetchProducts() {
        this.portalService.getApplications().subscribe((res) => {
            this.products = res;
            this.firstLoadingDone = true;
            this.loading = false;
        });
    }

    selectItem(item) {
        this.selectedProductId = item ? item.Access_UID : 0;
        this.productName = item ? item.AccessName : '';
        this.pageIndex = 0;

        this.getChangelogEntries();

        // update product dialog
        this.updateProductDialog();
    }

    public mobileProducts(type) {
        const originalSlectedProductId = this.selectedProductId;

        const emitProductSelect = new EventEmitter<PortalProduct>();
        emitProductSelect
            .pipe(takeUntil(this.destroy$))
            .subscribe(product => {
                this.selectItem(product);
            });

        let title = type === 'SUPPLIER' ? 'default.companysInstructions' : 'default.filterProducts';
        let group = type === 'SUPPLIER' ? GroupPriority.SUPPLIER : GroupPriority.HEADPOWER;

        this.productDialogRef = this.fsDialogService.open(ProductDialogComponent, {
            autoFocus: false,
            data: {
                dialogTitle: title,
                actionLabel: 'default.filter',
                products: [...this.products],
                selectedProductId: this.selectedProductId,
                GroupPriority: group,
                emitProductSelect
            } as ProductDialogDataInterface,
        });
        this.productDialogRef.afterClosed().subscribe((confirmed = false) => {
            if (!confirmed) {
                // revert to original state
                const originalProduct = this.products.find(
                    (p) => p.Access_UID === originalSlectedProductId,
                );
                this.selectItem(originalProduct);
            }
        });
    }

    private updateProductDialog() {
        if (this.productDialogRef && this.productDialogRef.componentInstance) {
            const dialogData = (this.productDialogRef.componentInstance as any)
                .dialogData;
            dialogData.selectedProductId = this.selectedProductId;
        }
    }
}
