import { Injectable } from '@angular/core';

@Injectable()
export class InstructionFormatterService {
  createInstruction(response): any {
    return {
      ...response.data,
      tags: this.getTags(response),
      instructionItems: this.getInstructionItems(response),
    };
  }

  private getTags(response: any): Array<any> {
    return response.data
      .relationships
      .field_tags
      .data
      .map((tag: any) => response.includedMap.get(tag.id))
      .filter((tag: any) => !!tag);
  }

  private getInstructionItems(response: any): Array<any> {
    return response.data
      .relationships
      .instruction_item
      .data
      .map((instructionItem: any) => response.includedMap.get(instructionItem.id))
      .filter((instructionItem: any) => !!instructionItem)
      .map((instructionItem: any) => ({
        ...instructionItem,
        thumbnail: this.getThumbnail(instructionItem, response.includedMap),
        paragraphs: this.getParagraphs(instructionItem, response.includedMap),
      }));
  }

  private getThumbnail(instructionItem: any, included: Map<string, any>): any {
    if (!instructionItem.relationships.field_thumbnail.data) {
      return;
    }

    const field = included.get(instructionItem.relationships.field_thumbnail.data.id);

    if (!field) {
      return;
    }

    const media = included.get(field.relationships.field_media_image.data.id);

    if (!media) {
      return;
    }

    return included.get(media.id);
  }

  private getParagraphs(node: any, included: Map<string, any>): Array<any> {
    return node.relationships
      .body
      .data
      .map((paragraph: any) => included.get(paragraph.id))
      .filter((paragraph: any) => !!paragraph)
      .map((paragraph: any) => ({
        ...paragraph,
      }));
  }
}
