import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
    FullScreenDialogComponent,
    FullScreenDialogService,
} from '@headpower/components';
import { LayoutBreakpointService } from '@headpower/layout';
import { Subject, takeUntil } from 'rxjs';
import {
    GroupPriority,
    PortalProduct,
} from 'src/app/modules/core/services/portal/portal-product';
import { PortalService } from 'src/app/modules/core/services/portal/portal.service';
import { Tag } from 'src/app/modules/shared/models/tag.model';
import {
    loadKeyword,
    loadProductId,
    loadSelectedTags,
    saveKeyword,
    saveProductId,
} from '../../shared';
import {
    ProductDialogComponent,
    ProductDialogDataInterface,
} from '../product-dialog/product-dialog.component';

@Component({
    selector: 'app-catalog-supplier',
    templateUrl: './catalog-supplier.component.html',
    styleUrls: ['./catalog-supplier.component.scss']
})
export class CatalogSupplierComponent implements OnInit, OnDestroy {

    products: PortalProduct[] = [];
    mobile = false;

    // state
    productName: string;
    selectedProductId = 0;
    selectedTags: Tag[] = [];
    keyword = '';
    selectableTags: Tag[] = [];
    resultCount = 0;

    // dialogRefs
    productDialogRef: MatDialogRef<FullScreenDialogComponent>;

    // other things
    public PREFIX = 'supplier';
    public localGroupPriority = GroupPriority;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private fsDialogService: FullScreenDialogService,
        private portalService: PortalService,
        private layoutBreakpointService: LayoutBreakpointService,
        private router: Router) { }

    ngOnInit() {
        this.restoreState();
        this.fetchProducts();

        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.mobile = result.handset);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private fetchProducts() {
        this.portalService.getApplications().subscribe((res) => {
            this.products = res;
            this.parseProductName();
        });
    }

    private parseProductName() {
        const product = this.products.find(
            (p) => p.Access_UID === this.selectedProductId,
        );
        if (product) {
            this.productName = product.AccessName;
        }
    }

    private restoreState() {
        this.keyword = loadKeyword(this.PREFIX);
        this.selectedTags = loadSelectedTags(this.PREFIX);
        this.selectedProductId = loadProductId(this.PREFIX);

        this.route.queryParams.subscribe((routeParams) => {
            const productIdFromRoute = parseInt(routeParams.productId, 10);
            if (productIdFromRoute && !this.selectedProductId) {
                // override product id if coming from params and we don't have another product selected
                this.selectedProductId = productIdFromRoute;
            } else if (!productIdFromRoute && this.selectedProductId) {
                // make sure the product id exists in the url
                this.router.navigate([], {
                    queryParams: { productId: this.selectedProductId },
                });
            }
        });
    }

    public selectProduct = (product: PortalProduct) => {
        if (product) {
            const { AccessName, Access_UID } = product;
            this.productName = AccessName;
            this.selectedProductId = Access_UID;
            saveProductId(this.PREFIX, Access_UID);
        } else {
            this.productName = '';
            this.selectedProductId = 0;
            saveProductId(this.PREFIX, 0);
        }

        // update tag dialog as well
        this.updateProductDialog();
    };

    public onKeywordChange(keyword: string): void {
        this.keyword = keyword;
        saveKeyword(this.PREFIX, keyword);
    }

    public updateSelectableTags(tags: Tag[]) {
        this.selectableTags = tags;
        this.updateProductDialog();
    }

    public updateResultCount(resultCount: number) {
        this.resultCount = resultCount;
        this.updateProductDialog();
    }

    private updateProductDialog() {
        if (this.productDialogRef && this.productDialogRef.componentInstance) {
            const dialogData = (this.productDialogRef.componentInstance as any)
                .dialogData;
            dialogData.selectedProductId = this.selectedProductId;
            dialogData.resultCount = this.resultCount;
        }
    }

    public mobileProducts() {
        const originalSlectedProductId = this.selectedProductId;

        const emitProductSelect = new EventEmitter<PortalProduct>();
        emitProductSelect
            .pipe(takeUntil(this.destroy$))
            .subscribe(product => {
                this.selectProduct(product);
            });

        this.productDialogRef = this.fsDialogService.open(ProductDialogComponent, {
            data: {
                dialogTitle: 'default.filterProducts',
                actionLabel: 'default.filter',
                selectedProductId: this.selectedProductId,
                products: [...this.products],
                GroupPriority: GroupPriority.SUPPLIER,
                emitProductSelect,
            } as ProductDialogDataInterface,
        });
        this.productDialogRef.afterClosed().subscribe((confirmed = false) => {
            if (!confirmed) {
                // revert to original state
                const originalProduct = this.products.find(
                    (p) => p.Access_UID === originalSlectedProductId,
                );
                this.selectProduct(originalProduct);
            }
        });
    }
}
