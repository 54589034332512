import { Injectable } from '@angular/core';
import { CatalogLevel } from '../../../shared/models/catalog-level.model';
import { Catalog } from '../../../shared/models/catalog.model';

@Injectable()
export class CatalogFormatterService {
  createCatalog(catalogs: Array<Catalog>): Array<CatalogLevel> {
    return this.createCatalogLevel(
      catalogs.filter((catalog: Catalog) => !catalog.hasParent).map((catalog: Catalog) => catalog.parent.id),
      catalogs.sort((first: Catalog, second: Catalog) => first.attributes.weight - second.attributes.weight),
    );
  }

  private createCatalogLevel(parentIds: Array<string>, catalogs: Array<Catalog>): Array<CatalogLevel> {
    return catalogs.filter((catalog: Catalog): boolean => parentIds.includes(catalog.parent.id))
      .map((catalog: Catalog): CatalogLevel => (({
        id: catalog.id,
        name: catalog.attributes.name,
        children: this.createCatalogLevel([catalog.id], catalogs),
        open: false,
        loading: false,
        loaded: false,
        instructions: [],
      })));
  }
}
