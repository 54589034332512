import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { map } from 'rxjs/operators';
import { CatalogService } from 'src/app/modules/core/services/catalog/catalog.service';
import { PortalProduct } from 'src/app/modules/core/services/portal/portal-product';
import { CatalogLevel } from 'src/app/modules/shared/models/catalog-level.model';
import { CatalogResponse } from 'src/app/modules/shared/models/catalog-response.model';
import { Tag } from 'src/app/modules/shared/models/tag.model';
import { clearScroll, createCatalogs, distinct, filterInstructions, loadOpenLevels, loadScroll, saveScroll, toggleLevel, scrollTo, createViewCatalog, getSelectableTags } from '../../shared';
import { CatalogFormatterService } from 'src/app/modules/core/services/catalog-formatter/catalog-formatter.service';
import { Instruction } from 'src/app/modules/shared/models/instruction.model';
import { TagGroup } from 'src/app/modules/shared/models/tag-group';
import { ChangelogService } from 'src/app/modules/shared/services/changelog.service';
import { ChangelogDialogComponent, ChangelogDialogDataInterface } from 'src/app/modules/shared/components/changelog-dialog/changelog-dialog.component';
import { FullScreenDialogComponent, FullScreenDialogService } from '@headpower/components';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Changelog } from 'src/app/modules/shared/models/changelog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-catalog-content',
    templateUrl: './catalog-content.component.html',
    styleUrls: ['./catalog-content.component.scss'],
})
export class CatalogContentComponent implements OnInit, OnChanges {
    @Input() products: PortalProduct[] = [];
    @Input() prefix = '';
    @Input() productName = '';
    @Input() keyword = '';
    @Input() selectedTags: Tag[] = [];
    @Input() selectedProductId: number | null = null;
    @Input() requireProduct = false;
    @Input() changelog = false;
    @Input() mobile: boolean;

    @Output() emitTagGroups: EventEmitter<TagGroup[]> = new EventEmitter();
    @Output() emitTags: EventEmitter<Tag[]> = new EventEmitter();
    @Output() emitSelectableTags: EventEmitter<Tag[]> = new EventEmitter();
    @Output() emitResultCount: EventEmitter<number> = new EventEmitter();

    catelogSubscription: Subscription;
    changelogSubscription: Subscription;

    // other
    loading = false;
    resultCount = 0;
    instructions: Instruction[] = [];
    catalogs: CatalogLevel[] = [];
    viewCatalogs: CatalogLevel[] = [];
    tags: Tag[] = [];
    openLevels: string[] = [];
    changelogDialogRef: MatDialogRef<FullScreenDialogComponent>;
    changelogEntries: Changelog[];
    view = [];
    lastClickedId: string = '';

    constructor(
        private catalogService: CatalogService,
        private catalogFormatter: CatalogFormatterService,
        private changelogService: ChangelogService,
        private fsDialogService: FullScreenDialogService,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        // restore open states
        this.openLevels = loadOpenLevels(this.prefix);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedProductId) {
            const productId: number = changes.selectedProductId.currentValue;
            this.fetchCatalog(productId || undefined);
            return; // exit as the .fetchCatalog will invoke updateViewCatalog() later
        }

        if (changes.keyword || changes.selectedTags) {
            this.lastClickedId = '';
            this.updateViewCatalog();
        }
    }

    public onToggle(level: CatalogLevel) {
        toggleLevel(level, this.openLevels, this.prefix);
    }

    // note: will change objects throuh the object reference
    private updateOpenLevels() {
        this.viewCatalogs.forEach((c) => {
            if (this.openLevels.some((o) => o === c.id)) {
                c.open = true;
            }
        });
    }

    public handleSaveScroll(id) {
        saveScroll(this.prefix, window.scrollY, id);
    }

    fetchChangelogEntries() {
        this.changelogEntries = [];

        if (this.changelog) {
            if (this.changelogSubscription) {
                this.changelogSubscription.unsubscribe();
            }
            if (this.selectedProductId) {
                if (this.changelogService.changelogEntries[this.selectedProductId]) {
                    this.changelogEntries = this.changelogService.changelogEntries[this.selectedProductId];
                } else {
                    this.changelogSubscription = this.changelogService
                        .getChangelogEntries(undefined, this.selectedProductId.toString())
                        .subscribe((res: any) => {
                            this.changelogService.changelogEntries[this.selectedProductId] = res.data;
                            this.changelogEntries = this.changelogService.changelogEntries[this.selectedProductId];
                        });
                }
            } else {
                this.changelogEntries = [];
            }
        }
    }

    showChangelog(e) {
        e.preventDefault();

        let dialogRef;
        let dialogData = {
            changelogEntries: this.changelogEntries,
            dialogTitle: 'default.changelogTitle',
            mobile: this.mobile
        }
        if (this.mobile) {
            dialogRef = this.fsDialogService.open(ChangelogDialogComponent, {
                autoFocus: false,
                data: dialogData as ChangelogDialogDataInterface
            });
        } else {
            dialogRef = this.dialog.open(ChangelogDialogComponent, {
                maxWidth: '960px',
                maxHeight: '90vh',
                autoFocus: false,
                data: dialogData
            });
        }

        dialogRef.afterClosed()
            .subscribe(result => { });

    }

    private fetchCatalog(productId: number) {
        this.loading = true;
        this.fetchChangelogEntries();

        if (this.catelogSubscription) {
            this.catelogSubscription.unsubscribe();
        }

        if (productId && this.catalogService.fetchedCatalog[productId]) {
            this.handleResponse(this.catalogService.fetchedCatalog[productId]);
        } else if (productId === undefined && this.catalogService.fetchedCatalog['all']) {
            this.handleResponse(this.catalogService.fetchedCatalog['all']);
        } else {
            this.catelogSubscription = this.catalogService
                .getCatalogsCustom(productId)
                .pipe(
                    map((catalogsResponse) =>
                        createCatalogs(catalogsResponse, this.catalogService)
                    ),
                )
                .subscribe((catalogsResponse) => {
                    this.handleResponse(catalogsResponse, productId || 'all')
                });
        }
    }

    public handleResponse(catalogResponse: CatalogResponse, type?): void {
        this.catalogService.fetchedCatalog[type] = catalogResponse;

        this.loading = false;

        if (catalogResponse.instructions) {
            this.instructions = catalogResponse.instructions;

            const tagGroups: TagGroup[] = [];
            this.instructions.forEach((instruction) => {
                const { relationships } = instruction;
                const { field_tags } = relationships;
                const { data = [] } = field_tags;
                data.forEach((d) => {
                    const parent = d.parents.find((p) => p.type === 'taxonomy_term');

                    // find or create tagGroup
                    let tagGroup = tagGroups.find((tg) => tg.id === parent.id);
                    if (!tagGroup) {
                        tagGroup = { id: parent.id, name: parent.name, tags: [] };
                        tagGroups.push(tagGroup);
                    }

                    // add tag to tagGroup
                    if (!tagGroup.tags.includes(d.id)) {
                        tagGroup.tags.push(d.id);
                    }
                });
            });

            this.emitTagGroups.emit(tagGroups);
        }

        if (catalogResponse.tags) {
            this.tags = catalogResponse.tags.sort((one: Tag, two: Tag): number =>
                one.attributes.name.localeCompare(two.attributes.name, 'fi'),
            );
        }

        this.catalogs = this.catalogFormatter.createCatalog(
            catalogResponse.catalog,
        );

        this.catalogs.forEach((catalog) => {
            catalog.instructions.push(
                ...this.instructions.filter((instruction) => {
                    return instruction.relationships.catalog.data.id === catalog.id;
                }),
            );
        });

        this.instructions.forEach(instruction => {
            instruction.category = this.catalogs.filter(ca => ca.id === instruction.relationships.catalog.data.id)[0].name || '';
        })

        this.updateOpenLevels();
        this.updateViewCatalog();

        // if we have scroll, scroll into it
        const scroll = loadScroll(this.prefix);
        if (scroll && scroll.scroll !== undefined) {
            setTimeout(() => {
                scrollTo(scroll.scroll);
                this.lastClickedId = scroll.lastClickedId;
                clearScroll(this.prefix);
            }, 0);
        }
    }

    private updateViewCatalog() {
        const instructions = filterInstructions(
            this.instructions,
            this.selectedTags,
            this.keyword,
        );
        // calculate catalog levels
        const levels = instructions
            .map((instruction) => {
                return instruction.relationships.catalog.data.id;
            })
            .filter(distinct);

        // store amount
        this.resultCount = instructions.length;
        this.emitResultCount.emit(this.resultCount);

        // store to local, will rerender!
        this.viewCatalogs = createViewCatalog(this.catalogs, instructions, levels);

        this.view = [];
        this.viewCatalogs.forEach(catalog => {
            this.view = [...this.view, ...catalog.instructions]
        })

        this.view.forEach(instruction => {
            // removes whitespace from both ends of name
            instruction.attributes.name = instruction.attributes.name.trim();
        })

        this.view.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))

        // emit selectable tags
        const selectableTags = getSelectableTags(
            this.tags,
            this.selectedTags,
            instructions,
        );
        this.emitSelectableTags.emit(selectableTags);

        // emit collection of tags
        this.emitTags.emit(this.tags);
    }
}
