<div class="changelog__container"
    [ngClass]="{'changelog__container--dialog': !isChangelogPage}">
    <hpo-card-content *ngIf="isChangelogPage && !mobile && firstLoadingDone"
        class="changelog__filters">
        <div class="changelog__filters-title">{{ 'default.filterProducts' | blah }}</div>
        <app-product-list [GroupPriority]="localGroupPriority.HEADPOWER"
            [products]="products"
            [stacked]="true"
            [selectedProductId]="selectedProductId"
            (emitProductSelect)="selectItem($event)">
        </app-product-list>

        <div class="supplier-products__container">
            <div class="changelog__filters-title">{{ 'default.companysInstructions' | blah }}:</div>
            <app-product-list [GroupPriority]="localGroupPriority.SUPPLIER"
                [products]="products"
                [stacked]="true"
                [catalogView]="true"
                [selectedProductId]="selectedProductId"
                (emitProductSelect)="selectItem($event)">
            </app-product-list>
        </div>
    </hpo-card-content>
    <hpo-card-content class="changelog__content">
        <div *ngIf="isChangelogPage"
            class="changelog-page--header">
            <div class="header-text">{{ 'default.changelogTitle' | blah }}</div>
            <ng-container *ngTemplateOutlet="paginator"
                [ngIf]="isChangelogPage"></ng-container>
        </div>
        <div *ngIf="loading"
            class="catalog__placeholders">
            <hpo-placeholder type="row"></hpo-placeholder>
            <hpo-placeholder type="row"></hpo-placeholder>
            <hpo-placeholder type="row"></hpo-placeholder>
        </div>
        <ng-container *ngIf="!loading">
            <mat-chip-list *ngIf="mobile"
                style="display: block; margin-bottom: 20px;">
                <mat-chip *ngIf="selectedProductId"
                    (removed)="selectItem(undefined)">
                    {{productName}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-list>
            <div *ngIf="selectedProductId === 0 && isChangelogPage">
                Valitse minkä ohjeistotuotteen muutoksia haluat tarkastella
            </div>
            <div *ngIf="changelogEntries[selectedProductId].length === 0 && selectedProductId !== 0">
                {{ 'default.changelogEmpty' | blah }}
            </div>
            <div *ngFor="let changelogEntry of changelogView"
                class="entry">
                <div class="title">
                    {{ changelogEntry.attributes.created | date }} - {{ changelogEntry.attributes.name }}
                </div>
                <div class="description"
                    [innerHTML]="changelogEntry.attributes.description.value || '-'"></div>
            </div>
            <div><ng-container *ngTemplateOutlet="paginator"></ng-container></div>
        </ng-container>
    </hpo-card-content>
</div>

<hpo-bottom-nav *ngIf="isChangelogPage && mobile">
    <hpo-nav-item icon="list"
        (click)="mobileProducts('SUPPLIER')">
        {{ 'default.companysInstructions' | blah }}
    </hpo-nav-item>
    <hpo-nav-item icon="list"
        (click)="mobileProducts('HEADPOWER')">
        {{ 'default.filterProducts' | blah }}
    </hpo-nav-item>
</hpo-bottom-nav>

<ng-template #paginator>
    <div *ngIf="changelogView.length"
        class="paginator">
        <div>
            {{ pageStart }} - {{ pageEnd }} / {{ length }} {{ nextLinks[this.selectedProductId] ? '+' : '' }}
        </div>
        <div class="paginator--buttons">
            <button mat-icon-button
                (click)="handlePageEvent('previous')"
                [disabled]="!pageIndex || loading">
                <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-icon-button
                (click)="pageEnd === length ? getMoreChangelogEntries() : handlePageEvent('next')"
                [disabled]="(pageEnd === length && !nextLinks[this.selectedProductId]) || loading">
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    </div>
</ng-template>