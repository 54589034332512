<ng-template [ngIf]="products && products.length > 0">
    <div class="supplier-layout">
        <div class="supplier-catalog">
            <ng-template [ngIf]="!mobile">
                <hpo-card-content class="supplier-products">
                    <div class="supplier-products-title">{{ 'default.companysInstructions' | blah }}:</div>
                    <app-product-list [GroupPriority]="localGroupPriority.SUPPLIER"
                        [products]="products"
                        [stacked]="true"
                        [selectedProductId]="selectedProductId"
                        (emitProductSelect)="selectProduct($event)">
                    </app-product-list>
                </hpo-card-content>
            </ng-template>

            <hpo-card-content class="supplier-items">
                <app-catalog-inline-search [keyword]="keyword"
                    (handleSearch)="onKeywordChange($event)"></app-catalog-inline-search>
                <app-catalog-content (emitSelectableTags)="updateSelectableTags($event)"
                    (emitResultCount)="updateResultCount($event)"
                    [keyword]="keyword"
                    [selectedProductId]="selectedProductId"
                    [selectedTags]="selectedTags"
                    [products]="products"
                    [prefix]="PREFIX"
                    [requireProduct]="true"
                    [productName]="productName"
                    [changelog]="true"
                    [mobile]="mobile">
                </app-catalog-content>
            </hpo-card-content>
        </div>
    </div>
</ng-template>

<ng-template #noInstructions>
    <hpo-placeholder type="row"></hpo-placeholder>
    <hpo-placeholder type="row"></hpo-placeholder>
    <hpo-placeholder type="row"></hpo-placeholder>
</ng-template>

<hpo-bottom-nav *ngIf="mobile">
    <hpo-nav-item icon="list"
        (click)="mobileProducts()">
        {{ 'default.filterProducts' | blah }}
    </hpo-nav-item>
</hpo-bottom-nav>