import { RelationshipReference } from '../../core/models/relationship-reference.model';
import { ResponseNode } from '../../core/models/response-node.model';
import { Category } from './category.model';
import { Deserializable } from './deserializable.model';
import { Resource } from './resource.model';
import { Tag } from './tag.model';

export class Instruction implements Deserializable {
    id: string;
    attributes: {
        name: string;
        path: {
            alias: string;
        };
    };
    relationships: {
        catalog: {
            data: RelationshipReference;
        };
        field_tags: {
            data: Array<RelationshipReference>;
        };
        field_category: {
            data: Array<RelationshipReference>;
        };
        instruction_item: {
            data: Array<RelationshipReference>;
        };
    };
    tags: Array<Tag>;
    categories: Array<Category>;
    category: string;

    deserialize(input: object, included: Map<string, ResponseNode>): this {
        Object.assign(this, input);

        if (this.relationships.field_tags.data) {
            this.tags = this
                .relationships
                .field_tags
                .data
                .map((tag: RelationshipReference): object => included.get(tag.id))
                .filter((tag: object): boolean => !!tag)
                .map((tag: object): Tag => new Tag().deserialize(tag));
        }

        if (this.relationships.field_category.data) {
            this.categories = this
                .relationships
                .field_category
                .data
                .map((category: RelationshipReference): object => included.get(category.id))
                .filter((category: object): boolean => !!category)
                .map((category: object): Category => new Category().deserialize(category, included));
        }

        return this;
    }

    get icon(): Resource {
        if (!(this.categories && this.categories.length)) {
            return null;
        }

        const [defaultCategory] = this.categories;

        return defaultCategory.icon;
    }
}
