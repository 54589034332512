import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY, catchError, switchMap, of } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { MappedCollectionResponse } from '../../models/mapped-collection-response.model';
import { MappedSingletonResponse } from '../../models/mapped-singleton-response.model';
import { mapCollectionProperties } from '../../operators/map-collection-properties.operator';
import { mapSingletonProperties } from '../../operators/map-singleton-properties.operator';

@Injectable()
export class InstructionService {

    private readonly instructionsBaseUrl: string = environment.instructionApiBaseUri + 'jsonapi/instruction/instruction';

    constructor(
        private http: HttpClient,
        private router: Router) { }

    public getOne(id: string): Observable<MappedSingletonResponse> {
        const include = [
            'instruction_item.body.field_image.field_media_image',
            'instruction_item.body.field_text_and_image_image.field_media_image',
            'instruction_item.body.field_instructions',
            'instruction_item.body.field_file_media.field_media_file',
            'instruction_item.field_thumbnail.field_media_image',
            'field_tags',
        ];

        const url = this.instructionsBaseUrl + `${id}?include=${include.join(',')}`;

        return this.http.get(url)
            .pipe(
                mapSingletonProperties,
                catchError(this.handleRequestError.bind(this))
            );
    }

    /**
     * Get instruction base by alias that includes an URL to actual instruction items
     * @param alias Instruction alias
     * @returns Observable InstructionBase
     */
    public getInstructionByAlias(alias: string): Observable<MappedSingletonResponse> {
        const url = environment.instructionApiBaseUri + `router/translate-path?path=/${alias}&_format=json`;

        return this.http.get(url)
            .pipe(
                switchMap((base: { jsonapi: { individual: string } }) => this.getInstruction(base.jsonapi.individual)),
                catchError(this.handleRequestError.bind(this))
            );
    }

    /**
     * Get instructions by individual instruction URI
     * @param instructionUri Individual instruction URI
     * @returns Observable of MappedSingletonResponse
     */
    public getInstruction(instructionUri: string): Observable<MappedSingletonResponse> {
        const include = [
            'instruction_item.body.field_image.field_media_image',
            'instruction_item.body.field_text_and_image_image.field_media_image',
            'instruction_item.body.field_instructions',
            'instruction_item.body.field_file_media.field_media_file',
            'instruction_item.field_thumbnail.field_media_image',
            'field_tags',
        ];

        const url = instructionUri + `?include=${include.join(',')}`;

        return this.http.get(url)
            .pipe(
                mapSingletonProperties,
                catchError(this.handleRequestError.bind(this))
            );
    }

    public checkInstructionUsageRight(alias: string): Observable<void> {
        const url = environment.instructionApiBaseUri + `router/translate-path?path=/${alias}&_format=json`;

        return this.http.get(url)
            .pipe(
                switchMap(() => of(undefined)),
                catchError(this.handleRequestError.bind(this))
            );
    }

    public getMany(instructions: string[]): Observable<MappedCollectionResponse> {
        const filters = [
            'filter[id][operator]=IN',
            ...instructions.map(id => `filter[id][value][]=${id}`),
        ];

        const url = this.instructionsBaseUrl + `?${filters.join('&')}`;

        return this.http.get(url)
            .pipe(
                mapCollectionProperties,
                catchError(this.handleRequestError.bind(this))
            );
    }

    private handleRequestError(error: HttpErrorResponse): Observable<never> {
        if (error.status === 403) {
            this.router.navigateByUrl('/no-usage-right', { skipLocationChange: true });
        } else {
            this.router.navigateByUrl('/page-not-found', { skipLocationChange: true });
        }

        return EMPTY;
    }
}
