import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BlahModule } from '@headpower/blah-ng';
import { ContentLayoutModule, SpinnerModule } from '@headpower/components';
import { LightboxModule } from 'ngx-lightbox';
import { NoUsageRightComponent } from './components/no-usage-right/no-usage-right.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RootComponent } from './components/root/root.component';
import { AddSearchComponent } from './components/add-search/add-search.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { ChangelogService } from './services/changelog.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        NoUsageRightComponent,
        PageNotFoundComponent,
        RootComponent,
        AddSearchComponent,
        ClickOutsideDirective,
        SitemapComponent,
        CopyrightComponent
    ],
    imports: [
        CommonModule,
        SpinnerModule,
        RouterModule,
        BlahModule,
        LightboxModule,
        ContentLayoutModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule
    ],
    exports: [
        AddSearchComponent,
        BlahModule,
        RouterModule,
        CommonModule,
        LightboxModule,
        ContentLayoutModule,
        CopyrightComponent,
        MatButtonModule,
        MatIconModule,
        SpinnerModule
    ],
    providers: [
        ChangelogService
    ]
})
export class SharedModule { }
