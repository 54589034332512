import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FloatingActionButtonService } from '@headpower/components';
import { ApplicationInsightsService, CurrentUser, LayoutBreakpointService, MenuItem } from '@headpower/layout';
import { OAuthService, AuthConfig, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { AppOAuthStorage } from '@headpower/angular-oauth2-oidc-extensions';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { map, filter, mergeMap } from 'rxjs';

import { environment } from '../environments/environment';
import { AppService } from './app.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public productId = 592;
    public currentUser = new CurrentUser();
    public locationUri: string;
    public portalBaseUri: string;
    public backendPortalUri: string;
    public accountBaseUri: string;
    public backendBaseUri: string;
    public menuItems: MenuItem[] = [];
    public claims: { [key: string]: any };
    public environmentVariables: any;
    public hideLayoutMenu: boolean;
    public productionMode: boolean;
    public mobile: boolean = false;
    public appVersion: string = environment.version;

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }
    private _isLoggedIn: boolean = false;

    constructor(
        private oAuthService: OAuthService,
        private oAuthStorage: AppOAuthStorage,
        private appService: AppService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private layoutBreakpointService: LayoutBreakpointService,
        private fabService: FloatingActionButtonService,
        private appInsightsService: ApplicationInsightsService
    ) {
        this.initBaseProperties();

        this.initAuthentication();

        // Initialize Application Insights
        this.appInsightsService.init(this.environmentVariables.applicationInsightsInstrumentationKey, true);
    }

    ngOnInit() {
        // Observe device screen size and change the layout accordingly
        this.layoutBreakpointService.observer$
            .subscribe(result => this.mobile = result.handset);

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.route),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                this.fabService.resetState();
                this.mobile = false;

                const fullTitle = 'HeadPower Ohjeistot';
                this.titleService.setTitle(
                    event.title ? `${event.title} | ${fullTitle}` : fullTitle,
                );
                this.locationUri = document.location.href;
            });
    }

    private initBaseProperties() {
        this.environmentVariables = {
            ...environment,
            config: {
                environmentName: environment.environmentName
            }
        };

        this.portalBaseUri = this.environmentVariables.portalBaseUri;
        this.backendPortalUri = this.environmentVariables.backendPortalUri;
        this.accountBaseUri = this.environmentVariables.accountBaseUri;
        this.productionMode = this.environmentVariables.production;
        this.backendBaseUri = this.environmentVariables.backendBaseUri;
    }

    public logOut() {
        this.oAuthStorage.clear();

        this.oAuthService.logOut();
    }

    private createAuthConfig() {
        const baseUri = document.baseURI.replace(/\/$/, ''); // Remove possible final slash
        const authConfig = new AuthConfig();

        // Identity provider's uri
        authConfig.issuer = this.environmentVariables.accountBaseUri + 'identity';

        // Set flow to authorization code
        authConfig.responseType = 'code';

        // Client secret, required by auth endpoint
        authConfig.dummyClientSecret = this.environmentVariables.clientSecret;

        // Auth redirect (callback) uri
        authConfig.redirectUri = baseUri + '/auth/callback';

        // Client id
        authConfig.clientId = 'headpower_web_app';

        // Scopes used by the app
        authConfig.scope = 'openid profile email offline_access headpower:api headpower:app roles';

        // Activate session checks to get event if session has terminated,
        // f.ex. user has logged out from another app.
        authConfig.sessionChecksEnabled = this.environmentVariables.production;
        authConfig.sessionCheckIntervall = 10000; // 10 seconds

        // Set tolerance for the token expiration time according to auth server
        authConfig.clockSkewInSec = 5 * 60; // 5 minutes

        // FYI: Debug should be enabled only when debugging login flow
        authConfig.showDebugInformation = false;

        return authConfig;
    }

    private initAuthentication() {
        this.oAuthService.configure(this.createAuthConfig());
        this.oAuthService.tokenValidationHandler = new JwksValidationHandler();

        // Uses refresh token so offline_access claim is mandatory
        this.oAuthService.setupAutomaticSilentRefresh();

        // Load discovery document. This will also start the login process.
        this.oAuthService.loadDiscoveryDocument();

        this.oAuthService.events
            .subscribe(event => {
                switch (event.type) {
                    case 'token_received':
                        // Token is received when user freshly authenticates and when token is silently refreshed.
                        // Load/refresh user profile after token is received.
                        this.oAuthService.loadUserProfile();
                        break;

                    case 'user_profile_loaded':
                        this.handlePostTokenReceive();

                        // Call login handlers only in login flow
                        if (!this.isLoggedIn) {
                            this.oAuthStorage.setAppItem('logged_in', 'true');

                            this.handleLoginSideEffects();
                            this.handlePostLogin(true);
                        }
                        break;

                    case 'session_changed':
                    case 'session_terminated':
                        console.log(`Authentication ${event.type.replace('_', ' ')}, logging out...`);
                        this.logOut();
                        break;
                }

                // Catch any error event and log them to AI
                if (event instanceof OAuthErrorEvent) {
                    console.error('[Auth]', event);

                    this.appInsightsService.trackException(
                        new Error(`${event.type} (${(event.reason as any)?.error?.error || '-'})`));
                }
            });

        // Check if user has authenticated before
        if (this.oAuthService.hasValidIdToken()) {
            this.handlePostTokenReceive();

            const wasLoggedIn = !!this.oAuthStorage.getAppItem('logged_in');

            // Check if user has authenticated in another app
            if (!wasLoggedIn) {
                this.oAuthStorage.setAppItem('logged_in', 'true');

                this.handleLoginSideEffects();
            }

            this.handlePostLogin(false);
        }
    }

    // FYI: This method is called asynchronously or synchronously after user has authenticated (freshly or before)
    //      and profile is loaded (login flow is in progress).
    //      Called also every time when token is silently refreshed.
    private handlePostTokenReceive() {
        // Set claims
        this.setClaimProperties();
    }

    // FYI: This method is called asynchronously or synchronously after user has authenticated (freshly or before).
    //      Meant for ex. data collection, previous session data removal etc.
    //      Called only once per app session.
    private handleLoginSideEffects() {
        // Send product usage statistics
        const redirectUrl = this.oAuthStorage.getAppItem('post_login_redirect_url') || '/';

        this.appService.sendPageUsageStatistics(redirectUrl)
            .subscribe();
    }

    // FYI: This method is called once in two possible ways:
    //      1. Asynchronously (when user has freshly authenticated).
    //      2. Synchronously from constructor (when user has authenticated before in current session).
    //
    //      To comply with both cases ensure that:
    //        - synchronous initializers (constructor, ngOnInit etc.) wont access properties which are not defined until in this method.
    //        - every property this method will access is defined before initAuthenticaton call, e.g. menuItems.
    private handlePostLogin(freshLogin: boolean) {
        this._isLoggedIn = true;

        // Add menu items
        this.setAppMenuItems();

        // Add page loaded class for robot tests
        setTimeout(() => document.body.classList.add('page-loaded'), 250);
    }

    private setClaimProperties() {
        this.claims = this.oAuthService.getIdentityClaims();

        /* tslint:disable:no-string-literal */
        this.currentUser.userId = this.claims['sub'];
        this.currentUser.givenName = this.claims.given_name;
        this.currentUser.familyName = this.claims.family_name;
        this.currentUser.email = this.claims.email;
        this.currentUser.picture = this.claims.picture;
        this.currentUser.accessToken = this.oAuthService.getAccessToken();
        this.currentUser.companyName = this.claims['headpower:company_name'];
    }

    private setAppMenuItems() {
        this.menuItems = [
            new MenuItem('default.catalogLanding.home.title', '/catalog/headpower', { translate: true, icon: 'home' }),
            new MenuItem('default.catalogLanding.search.title', '/catalog/search', { translate: true, icon: 'search' }),
            new MenuItem('default.catalogLanding.customer.title', '/catalog/supplier', { translate: true, icon: 'list' }),
            new MenuItem('default.catalogLanding.changeLog.title', '/changelog', { translate: true, icon: 'list' })
        ];
    }
}
