import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TagGroup } from 'src/app/modules/shared/models/tag-group';
import { Tag } from 'src/app/modules/shared/models/tag.model';

export interface TagDialogDataInterface {
  actionClicked?: any;

  // normal data references
  tags: Tag[];
  tagGroups: TagGroup[];
  selectedTags: Tag[];
  selectableTags: Tag[];

  // methods
  emitSelectedTags: EventEmitter<Tag[]>;
}

@Component({
  selector: 'app-tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.scss'],
})
export class TagDialogComponent implements OnInit {
  selectedTags: Tag[] = [];
  selectableTags: Tag[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TagDialogDataInterface,
    private dialogRef: MatDialogRef<TagDialogComponent>,
  ) {}

  ngOnInit(): void {
    const dialogAction = this.data.actionClicked as Observable<void>;

    dialogAction.subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  public selectTags(tags: Tag[]) {
    // delegate selection to parent
    this.data.emitSelectedTags.emit(tags);
  }
}
