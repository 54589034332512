import { NgModule } from '@angular/core';
import { PlaceholderModule } from '@headpower/components';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { SharedModule } from '../shared/shared.module';
import { FileComponent } from './components/file/file.component';

@NgModule({
    declarations: [
        FileComponent
    ],
    imports: [
        SharedModule,
        PlaceholderModule,
        MatProgressBarModule
    ]
})
export class FileModule { }
