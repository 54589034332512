import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';

export const getAllResults = (http: HttpClient) => <T>(source: Observable<T>) => source.pipe(
  expand((response: any) => {
    if (response.links.next) {
      return http.get(response.links.next.href);
    }

    return EMPTY;
  }),
  reduce((accumulator, next: any) => ({
    data: [
      ...accumulator.data,
      ...(next.data || []),
    ],
    included: [
      ...accumulator.included,
      ...(next.included || []),
    ],
  }), { data: [], included: [] }),
);
