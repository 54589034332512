import { CatalogService } from '../core/services/catalog/catalog.service';
import { CatalogLevel } from '../shared/models/catalog-level.model';
import { Instruction } from '../shared/models/instruction.model';
import { Tag } from '../shared/models/tag.model';

const storage = sessionStorage;

export const createCatalogs = (
    catalogsResponse,
    catalogService: CatalogService,
) => {
    const cats = [];
    const insts = [];
    const tags = [];

    Object.values(catalogsResponse).map((catalogsResponseItem) => {
        cats.push(catalogService.createCatalogItem(catalogsResponseItem));
        insts.push(
            ...catalogService.createCatalogInstruction(
                catalogsResponseItem,
                tags,
                [],
            ),
        );
    });

    return {
        catalog: cats,
        instructions: insts,
        tags: Array.from(tags),
    };
};

export const saveSelectedTags = (prefix: string, selectedTags: Tag[]) => {
    storage.setItem(`${prefix}-tags`, JSON.stringify(selectedTags));
};

export const loadSelectedTags = (prefix: string) => {
    const tags = storage.getItem(`${prefix}-tags`);

    if (!tags) {
        return [] as Tag[];
    }

    return JSON.parse(tags) as Tag[];
};

export const saveKeyword = (prefix: string, keyword: string) => {
    storage.setItem(`${prefix}-keyword`, keyword);
};

export const loadKeyword = (prefix: string) => {
    return (storage.getItem(`${prefix}-keyword`) || '') as string;
};

export const distinct = (value: string, index: number, self: any) => {
    return self.indexOf(value) === index;
};

export const filterInstructions = (
    instructionsParam,
    selectedTagsParam,
    keywordParam,
) => {
    let instructions = filterInstructionByTag(
        instructionsParam,
        selectedTagsParam,
    );
    instructions = filterInstructionByKeyword(instructions, keywordParam);
    return instructions;
};

export const filterInstructionByTag = (
    instructions: Instruction[],
    selectedTags: Tag[],
) => {
    return instructions.filter((instruction) => {
        return selectedTags.every((stag) => {
            return instruction.relationships.field_tags.data.some((tag) => {
                return tag.id === stag.id;
            });
        });
    });
};

export const filterInstructionByKeyword = (
    instructions: Instruction[],
    keyword: string,
) => {
    return instructions.filter((instruction) => {
        return instruction.attributes.name.toLowerCase().includes(keyword.toLowerCase()) || instruction.category?.toLowerCase().includes(keyword.toLowerCase());
    });
};

export const getSelectableTags = (
    tags: Tag[],
    selectedTags: Tag[],
    instructions: Instruction[],
): Tag[] => {
    const tagRelationships = instructions.map((instruction) => {
        return instruction.relationships.field_tags.data.map((tag) => {
            if (tag.id !== 'missing') {
                return tag;
            }
        });
        // return instruction.tags;
    });

    const instructionTags = !!tagRelationships.length
        ? tagRelationships
            .reduce((acc, cur) => {
                return acc.concat(cur);
            })
            .map((tag) => {
                if (!!tag) {
                    return tag.id;
                }
            })
            .filter(distinct)
        : [];

    const availableTags = tags.filter((tag) => {
        return instructionTags.some((it) => {
            return it === tag.id;
        });
    });

    return availableTags.filter((tag) => {
        return !selectedTags.some((st) => st.id === tag.id);
    });
};

export const saveOpenLevels = (prefix: string, openLevels: string[]) => {
    storage.setItem(`${prefix}-openLevels`, JSON.stringify(openLevels));
};

export const loadOpenLevels = (prefix: string): string[] => {
    try {
        return JSON.parse(storage.getItem(`${prefix}-openLevels`)) || [];
    } catch (err) {
        return [];
    }
};

export const saveScroll = (prefix: string, scroll: number, id: string) => {
    storage.setItem(`IWI-lastClickedId`, id);
    storage.setItem(`${prefix}-scroll`, scroll.toString());
};

export const loadScroll = (prefix: string) => {
    const scroll = storage.getItem(`${prefix}-scroll`);
    const lastClickedId = storage.getItem(`IWI-lastClickedId`);
    if (!scroll) {
        return undefined;
    }
    return { scroll: parseInt(scroll, 10), lastClickedId: lastClickedId };
};

export const clearScroll = (prefix: string) => {
    storage.removeItem(`${prefix}-scroll`);
    storage.removeItem(`IWI-lastClickedId`)
};

// Using setTimeout to let the component draw the list before jumping to the last position
export const scrollTo = (top: number) =>
    window.scrollTo({
        top: top - 76,
        left: 0,
    });

export const toggleLevel = (
    level: CatalogLevel,
    openLevelsRef: string[],
    prefix: string,
) => {
    // find current status
    const savedLevelId = openLevelsRef.find((o) => o === level.id);

    // check if we have an entry
    if (level.open && savedLevelId) {
        openLevelsRef.splice(
            openLevelsRef.findIndex((o) => o === level.id),
            1,
        );
    }

    if (!level.open && !savedLevelId) {
        openLevelsRef.push(level.id);
    }

    // toggle in the model
    level.open = !level.open;

    // persist
    storage.setItem(`${prefix}-openLevels`, JSON.stringify(openLevelsRef));
};

export const loadProductId = (prefix: string) => {
    const productId = parseInt(storage.getItem(`${prefix}-productId`), 10);
    return productId || undefined;
};

export const saveProductId = (prefix: string, productId: number) => {
    storage.setItem(`${prefix}-productId`, productId.toString());
};

export const clearProductId = (prefix: string) =>
    storage.removeItem(`${prefix}-productId`);

export const createViewCatalog = (
    catalogs: CatalogLevel[],
    instructions: Instruction[],
    levels: string[],
) => {
    // build view catalog
    const viewCatalog = catalogs.filter((level) => {
        return levels.some((id) => id === level.id);
    });

    viewCatalog.forEach((level) => {
        level.instructions = [];
        level.instructions = instructions
            .filter((instruction) => {
                return instruction.relationships.catalog.data.id === level.id;
            })
            .sort((one: Instruction, two: Instruction): number =>
                one.attributes.name.localeCompare(two.attributes.name, 'fi'),
            );
    });

    return viewCatalog;
};
