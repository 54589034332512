import { Component, Input, OnInit } from '@angular/core';
import { ToasterService } from '@headpower/layout';
import { BlahService } from '@headpower/blah-ng';
import { EmptyError } from 'rxjs';

import { ParagraphComponent } from '../../../models/paragraph-component/paragraph-component';
import { ResourceEntry, ResourceService } from 'src/app/modules/shared/services/resource.service';

interface FileEntry {
    name: string;
    nameEncoded: string;
    icon: string;
    path: string;
    pathEncoded: string;
    size: number;
}

@Component({
    selector: 'app-files-paragraph',
    templateUrl: './files-paragraph.component.html',
    styleUrls: ['./files-paragraph.component.scss']
})
export class FilesParagraphComponent implements OnInit, ParagraphComponent {

    @Input() public data: any;

    public aliasEncoded!: string;

    public files: FileEntry[] = [];

    public loadingFile?: number;

    constructor(
        private resourceService: ResourceService,
        private toaster: ToasterService,
        private blahService: BlahService) { }

    ngOnInit() {
        this.aliasEncoded = encodeURIComponent(this.data.full.data.attributes.path.alias.substring(1));

        this.files = this.buildFileList();
    }

    public async downloadFile(file: FileEntry, fileIndex: number) {
        if (isFinite(this.loadingFile)) {
            if (this.loadingFile !== fileIndex) {
                this.toaster.create(this.blahService.blah('default.previousDownloadInProgress'));
            }

            return;
        }

        this.loadingFile = fileIndex;

        let resource: ResourceEntry;

        try {
            resource = await this.resourceService.getResource('file', file.path, file.name);
        }
        catch (error) {
            this.loadingFile = undefined;

            // EmptyError is thrown if service disposes the resources while request is in progress.
            //
            // This happens when user navigates away from the instruction while downloading,
            // so just ignore the error and return.
            if (error instanceof EmptyError) {
                return;
            }

            this.toaster.create(this.blahService.blah('default.errorWhileDownloadingFile'), {
                color: 'warn'
            });

            console.error(error);
            return;
        }

        this.loadingFile = undefined;

        const link = document.createElement('a');

        link.href = resource.objectUrl;
        link.download = file.name;

        link.dispatchEvent(new MouseEvent('click'));
    }

    private buildFileList() {
        const mediaData: any[] = this.data.self.relationships.field_file_media.data;

        return mediaData
            .map(file => this.data.full.includedMap.get(file.id))
            .filter(file => file)
            .map(file => this.data.full.includedMap.get(file.relationships.field_media_file.data.id))
            .filter(file => file)
            .map(file => ({
                name: file.attributes.filename,
                nameEncoded: encodeURIComponent(file.attributes.filename),
                icon: this.resolveIcon(file.attributes.filemime),
                path: file.attributes.uri.url, // Not actually url, just a path on the server.
                pathEncoded: encodeURIComponent(file.attributes.uri.url),
                size: file.attributes.filesize
            } as FileEntry));
    }

    private resolveIcon(mimeType: string) {
        switch (mimeType) {
            case 'text/plain': // txt
            case 'application/rtf': // rtf
            case 'application/msword': // doc
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // docx
                return 'description';

            case 'application/pdf': // pdf
                return 'picture_as_pdf';

            case 'image/png': // png
            case 'image/jpeg': // jpg
            case 'image/gif': // gif
                return 'image';

            case 'application/vnd.ms-excel': // xsl
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': // xslx
                return 'difference';

            case 'application/vnd.ms-powerpoint': // ppt
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': // pptx
                return 'insert_chart';

            default:
                return 'insert_drive_file';
        }
    }
}