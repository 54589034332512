import { RelationshipReference } from '../../core/models/relationship-reference.model';
import { ResponseNode } from '../../core/models/response-node.model';
import { Deserializable } from './deserializable.model';
import { Resource } from './resource.model';

export class Category implements Deserializable {
  id: string;
  attributes: {
    name: string;
  };
  relationships: {
    field_icon: {
      data: RelationshipReference;
    };
  };
  icon: Resource;

  deserialize(input: object, included: Map<string, ResponseNode>): this {
    Object.assign(this, input);

    if (this.relationships.field_icon.data && included.has(this.relationships.field_icon.data.id)) {
      this.icon = new Resource().deserialize(included.get(this.relationships.field_icon.data.id));
    }

    return this;
  }
}
