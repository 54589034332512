export enum GroupPriority {
  HEADPOWER = 0,
  SUPPLIER = 10,
}

export interface PortalProduct {
  Access_UID: number;
  AccessName: string;
  AccessGroupName: string;
  IsInstructionsProduct: boolean;
  GroupPriority: GroupPriority;
}
