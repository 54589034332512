import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Changelog } from '../models/changelog';

@Injectable({
    providedIn: 'root',
})
export class ChangelogService {
    private readonly changelogBaseUrl: string = `${environment.instructionApiBaseUri}jsonapi/changelog_entry/changelog_entry`;
    private readonly defaultHeaders: { [header: string]: string } = {};
    public changelogEntries = {};

    constructor(private http: HttpClient, oAuthService: OAuthService) {
        this.defaultHeaders = {
            Authorization: `Bearer ${oAuthService.getAccessToken()}`,
        };
    }

    public getChangelogEntries(
        instructionId?: string,
        productId?: string,
    ): Observable<Array<Changelog>> {
        const filters = [];
        if (instructionId) {
            filters.push(`filter[instruction.id]=${instructionId}`);
        }
        if (productId) {
            filters.push(
                `filter[instruction.permission_category.product_id]=${productId}`,
            );
        }

        const url = `${this.changelogBaseUrl}?sort=-created&page[limit]=100&${filters.join('&')}`;

        return this.getChangelogEntriesByUrl(url)
    }

    public getChangelogEntriesByUrl(url) {
        return this.http
            .get(url, {
                headers: this.defaultHeaders,
                withCredentials: true,
            })
            .pipe(
                map((response: any) => response));
    }
}
