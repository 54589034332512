import { Component, Input, OnInit } from '@angular/core';

import { InstructionService } from '../../../../core/services/instruction/instruction.service';
import { ParagraphComponent } from '../../../models/paragraph-component/paragraph-component';

@Component({
    selector: 'app-instruction-list-paragraph',
    templateUrl: './instruction-list-paragraph.component.html',
    styleUrls: ['./instruction-list-paragraph.component.scss']
})
export class InstructionListParagraphComponent implements OnInit, ParagraphComponent {

    @Input() public data: any;

    public tags?: any[];

    constructor(
        private instructions: InstructionService) { }

    ngOnInit() {
        const instructions = this.data.self.relationships.field_instructions.data
            .map((relation: any) => relation.id as string);

        this.instructions
            .getMany(instructions)
            .subscribe(result => {
                this.tags = result.data
                    .map((node: any) => ({
                        path: node.attributes.path.alias,
                        name: node.attributes.name
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name));
            });
    }
}
