import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  GroupPriority,
  PortalProduct,
} from 'src/app/modules/core/services/portal/portal-product';

export interface ProductDialogDataInterface {
  actionClicked?: any;

  // normal data references
  selectedProductId: number;
  products: PortalProduct[];
  GroupPriority: GroupPriority;

  // methods
  emitProductSelect: EventEmitter<PortalProduct | undefined>;
}

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
})
export class ProductDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProductDialogDataInterface,
    private dialogRef: MatDialogRef<ProductDialogComponent>,
  ) {}

  ngOnInit(): void {
    const dialogAction = this.data.actionClicked as Observable<void>;

    dialogAction.subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  public selectProduct(product: PortalProduct) {
    // delegate selection to parent
    this.data.emitProductSelect.emit(product);
  }
}
