<div class="image-paragraph">

    <hpo-placeholder *ngIf="loading"
        type="row"></hpo-placeholder>

    <ng-container *ngIf="!loading"
        [ngSwitch]="type">

        <ng-container *ngSwitchCase="'media--video'">

            <div class="image-paragraph__video">
                <iframe class="image-paragraph__iframe"
                    [src]="source"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen></iframe>
            </div>

            <figure>
                <figcaption *ngIf="caption">
                    {{caption}}
                </figcaption>
            </figure>

        </ng-container>

        <ng-container *ngSwitchCase="'media--image'">

            <ng-container *ngIf="mobile">

                <a [href]="source"
                    target="_blank"
                    class="image-paragraph__link">
                    <figure>
                        <img [src]="source"
                            alt="Image"
                            class="image-paragraph__image">
                        <figcaption *ngIf="caption">
                            {{caption}}
                        </figcaption>
                    </figure>
                </a>

            </ng-container>

            <ng-container *ngIf="!mobile">

                <figure>
                    <img [src]="source"
                        alt="Image"
                        class="image-paragraph__image"
                        (click)="openLightbox()">
                    <figcaption *ngIf="caption">
                        {{caption}}
                    </figcaption>
                </figure>

            </ng-container>

        </ng-container>

    </ng-container>

</div>