import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Instruction } from '../../../shared/models/instruction.model';
import { createQueryString } from '../../helpers/create-query-string.helper';
import { createCatalogObjects } from '../../operators/create-catalog-objects.operator';
import { createInstructionObjects } from '../../operators/create-instruction-objects.operator';
import { getAllResults } from '../../operators/get-all-results.operator';
import { mapCollectionProperties } from '../../operators/map-collection-properties.operator';
import { CatalogResponse } from 'src/app/modules/shared/models/catalog-response.model';

@Injectable()
export class CatalogService {
    private readonly catalogBaseUrl: string = `${environment.instructionApiBaseUri}jsonapi/taxonomy_term/catalog`;
    private readonly instructionsBaseUrl: string = `${environment.instructionApiBaseUri}jsonapi/instruction/instruction`;
    private readonly catalogsBaseUrlCustom: string = `${environment.instructionApiBaseUri}api/catalog`;
    public fetchedCatalog = {};

    constructor(private http: HttpClient) { }

    /**
     * Get all categories
     * @param product Optional product filter
     * @param category Optional category filter
     * @param tags Optional tags for filtering
     * @returns An observable array of categories
     */
    public getAll(
        product: string = null,
        categories: string[] = [],
    ): Observable<CatalogResponse> {
        let includeCommonInstructions = false;

        if (categories.includes('common')) {
            categories.splice(
                categories.findIndex((item) => item === 'common'),
                1,
            );
            includeCommonInstructions = true;
        }

        const categoryFilterString = (category: string, index: number) => {
            return (
                `&filter[category-${index}][condition][operator]=%3D` + // equals
                `&filter[category-${index}][condition][value]=${category}` +
                `&filter[category-${index}][condition][path]=instructions.field_category.id` +
                `&filter[category-${index}][condition][memberOf]=category-group`
            );
        };

        const commonFilterString = (index: number) => {
            return (
                `&filter[category-${index}][condition][operator]=IS NULL` + // equals
                `&filter[category-${index}][condition][path]=instructions.field_category.id` +
                `&filter[category-${index}][condition][memberOf]=category-group`
            );
        };

        let categoriesFilter =
            `&filter[category-group][group][conjunction]=OR` +
            categories
                .map((category, index) => categoryFilterString(category, index))
                .join('');

        if (includeCommonInstructions) {
            categoriesFilter =
                categoriesFilter + commonFilterString(categories.length);
        }

        const filters = createQueryString({
            'filter[instructions.permission_category.product_id]': product,
        });

        const include = ['instructions.field_tags'];

        return this.http
            .get(
                `${this.catalogBaseUrl}?${filters}${categories.length > 0 || includeCommonInstructions
                    ? categoriesFilter
                    : ''
                }&include=${include.join(',')}`,
            )
            .pipe(
                getAllResults(this.http),
                mapCollectionProperties,
                createCatalogObjects,
            );
    }

    /**
     * Get one category
     * @param id Category id
     * @param product Optional product filter
     */
    public getOne(
        id: string,
        product: string = null,
    ): Observable<Array<Instruction>> {
        const include = [
            'field_category.field_icon',
            'permission_category',
            'field_tags',
        ];
        const filters = createQueryString({
            'filter[catalog.id]': id,
            'filter[permission_category.product_id]': product,
        });

        return this.http
            .get(
                `${this.instructionsBaseUrl}?include=${include.join(',')}&${filters}`,
            )
            .pipe(mapCollectionProperties, createInstructionObjects);
    }

    public getAllCatalogs(): Observable<any> {
        return this.http
            .get(`${this.catalogBaseUrl}`)
            .pipe(
                getAllResults(this.http),
                mapCollectionProperties,
                createCatalogObjects,
            );
    }

    public getCatalogs(product: string = null, categories: string[] = []) {
        let includeCommonInstructions = false;

        if (categories.includes('common')) {
            categories.splice(
                categories.findIndex((item) => item === 'common'),
                1,
            );
            includeCommonInstructions = true;
        }

        const categoryFilterString = (category: string, index: number) => {
            return (
                `&filter[category-${index}][condition][operator]=%3D` + // equals
                `&filter[category-${index}][condition][value]=${category}` +
                `&filter[category-${index}][condition][path]=instructions.field_category.id` +
                `&filter[category-${index}][condition][memberOf]=category-group`
            );
        };

        const commonFilterString = (index: number) => {
            return (
                `&filter[category-${index}][condition][operator]=IS NULL` + // equals
                `&filter[category-${index}][condition][path]=instructions.field_category.id` +
                `&filter[category-${index}][condition][memberOf]=category-group`
            );
        };

        let categoriesFilter =
            `&filter[category-group][group][conjunction]=OR` +
            categories
                .map((category, index) => categoryFilterString(category, index))
                .join('');

        if (includeCommonInstructions) {
            categoriesFilter =
                categoriesFilter + commonFilterString(categories.length);
        }

        const filters = createQueryString({
            'filter[instructions.permission_category.product_id]': product,
        });

        const include = ['instructions'];

        return this.http
            .get(
                `${this.catalogBaseUrl}?${filters}${categories.length > 0 || includeCommonInstructions
                    ? categoriesFilter
                    : ''
                }&include=${include.join(',')}`,
            )
            .pipe(
                getAllResults(this.http),
                mapCollectionProperties,
                createCatalogObjects,
            );
    }

    public getCatalogsCustom(productId: number | null) {
        let url = `${this.catalogsBaseUrlCustom}`;
        if (productId) {
            url += `?permission_category[]=${productId.toString()}`;
        }

        return this.http.get(url);
    }

    public createCatalogItem(catalogItem) {
        return {
            id: catalogItem.id,
            type: catalogItem.type,
            parent: { id: 'virtual' },
            attributes: {
                name: catalogItem.name,
                weight: catalogItem.weight,
            },
        };
    }

    public createCatalogInstruction(catalogItem, tags, categoryOptions) {
        return catalogItem.instructions.map((instruction) => {
            if (!Array.isArray(instruction.tags)) {
                instruction.tags = Object.values(instruction.tags)
            }
            instruction.tags.reduce((_, next) => {
                if (tags.find((x) => x.id === next.id)) {
                    return;
                }

                tags.push({
                    id: next.id,
                    attributes: { name: next.name },
                });
            }, []);

            if (instruction.category !== null) {
                if (
                    !categoryOptions.find((co) => co.value === instruction.category.id)
                ) {
                    categoryOptions.push({
                        name: instruction.category.name,
                        value: instruction.category.id,
                    });
                }
            }

            return {
                id: instruction.id,
                attributes: {
                    name: instruction.name,
                    path: { alias: instruction.href },
                },
                relationships: {
                    catalog: { data: { id: catalogItem.id } },
                    field_tags: { data: instruction.tags },
                    field_category: { data: instruction.category },
                    instruction_item: { data: [] },
                },
                tags: instruction.tags,
            };
        });
    }
}
