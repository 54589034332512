import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogHeadpowerComponent } from './modules/catalog/components/catalog-headpower/catalog-headpower.component';
import { CatalogSearchComponent } from './modules/catalog/components/catalog-search/catalog-search.component';
import { CatalogSupplierComponent } from './modules/catalog/components/catalog-supplier/catalog-supplier.component';
import { AuthGuard } from './modules/core/guards/auth.guard';
import { InstructionByAliasComponent } from './modules/instruction/components/instruction-by-alias/instruction-by-alias.component';
import { InstructionByUuidComponent } from './modules/instruction/components/instruction-by-uuid/instruction-by-uuid.component';
import { NoUsageRightComponent } from './modules/shared/components/no-usage-right/no-usage-right.component';
import { PageNotFoundComponent } from './modules/shared/components/page-not-found/page-not-found.component';
import { RootComponent } from './modules/shared/components/root/root.component';
import { SitemapComponent } from './modules/shared/components/sitemap/sitemap.component';
import { InstructionsForTagComponent } from './modules/tag/components/instructions-for-tag/instructions-for-tag.component';
import { FileComponent } from './modules/file/components/file/file.component';
import { ChangelogComponent } from './modules/catalog/components/changelog/changelog.component';
import { AuthCallbackComponent } from './auth/callback/callback.component';

const routes: Routes = [
    {
        path: 'auth/callback',
        component: AuthCallbackComponent
    },
    {
        path: '',
        component: RootComponent,
        children: [
            {
                path: 'catalog/search',
                component: CatalogSearchComponent,
                pathMatch: 'full',
                canActivate: [AuthGuard]
            },
            {
                path: 'catalog/headpower',
                component: CatalogHeadpowerComponent,
                pathMatch: 'full',
                canActivate: [AuthGuard]
            },
            {
                path: 'catalog/supplier',
                component: CatalogSupplierComponent,
                pathMatch: 'full',
                canActivate: [AuthGuard]
            },
            {
                path: 'catalog',
                component: CatalogHeadpowerComponent,
                pathMatch: 'full',
                canActivate: [AuthGuard]
            },
            {
                path: 'changelog',
                component: ChangelogComponent,
                pathMatch: 'full',
                canActivate: [AuthGuard]
            },
            {
                path: 'instruction/:id',
                component: InstructionByUuidComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'tag/:id',
                component: InstructionsForTagComponent,
                canActivate: [AuthGuard]
            },
            {
                path: ':id/:year',
                component: InstructionByAliasComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'file/:alias/:path/:name',
                component: FileComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'sitemap.xml',
                component: SitemapComponent,
                canActivate: [AuthGuard]
            },
            {
                path: '',
                redirectTo: '/catalog/headpower',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'page-not-found',
        component: PageNotFoundComponent
    },
    {
        path: 'no-usage-right',
        component: NoUsageRightComponent
    },
    // TODO 
    { path: 'Login', redirectTo: '', pathMatch: 'prefix' },  // NOTE: This is just a temporary routing to make things work smoothly when shifting to new login flow. Remove this when not needed anymore.
    // TODO
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }