import { map } from 'rxjs/operators';
import { Catalog } from '../../shared/models/catalog.model';
import { MappedCollectionResponse } from '../models/mapped-collection-response.model';
import { ResponseNode } from '../models/response-node.model';
import { Tag } from '../../shared/models/tag.model';
import { Instruction } from '../../shared/models/instruction.model';

const getInstructions = (response: any): Instruction[] => {
  return response.included.filter((entity) => entity.type === 'instruction--instruction');
};

const getTags = (response: any): Tag[] => {
  return response.included.filter((entity) => entity.type === 'taxonomy_term--tags');
};

export const createCatalogObjects = map((response: MappedCollectionResponse) => {
  return {
    catalog: response.data.map((element: ResponseNode): Catalog => new Catalog().deserialize(element, response.dataMap)),
    instructions: getInstructions(response),
    tags: getTags(response),
  };
});
