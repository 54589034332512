import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { AppOAuthStorage } from '@headpower/angular-oauth2-oidc-extensions';
import { Subject, filter, take, takeUntil } from 'rxjs';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss']
})
export class AuthCallbackComponent implements OnInit, OnDestroy {

    public errors: string[] = [];

    private destroy$: Subject<void> = new Subject();

    constructor(
        private oAuthService: OAuthService,
        private oAuthStorage: AppOAuthStorage,
        private router: Router,
        private route: ActivatedRoute) { }

    ngOnInit() {
        // This component is initiated twice during login flow
        // due to router-outlet being moved to hpo-layout after successful authentication.
        //
        // Initiation count is marked with number following # in comments.

        // FYI: As tryLogin removes query params from url after authentication by replacing the state
        //      the route snapshot wont update correctly and hasAuthCode will be falsely true in second initiation.
        const hasAuthCode = this.route.snapshot.queryParamMap.has('code');

        // 1# In first initiation we should have just returned from auth code endpoint, but tokens have not been fetched yet.
        //    Auth code should exist in query params, so this step is omitted.
        //
        // 2# In second initiation user should be successfully authenticated and tokens fetched.
        //    Get previously set post login redirect url and redirect user back to the page, replacing this page.
        //
        // If this component is accessed after the login flow user is redirected to same page as before.
        // If accessed before the flow user is redirected to startup page (which will eventually initiate the login flow).
        //
        // User can get stuck here if accessed with code parameter without authentication,
        // but this happens only if user manually navigates to this page and manually sets the code parameter.
        if (this.oAuthService.hasValidIdToken() || !hasAuthCode) {
            const redirectUrl = this.oAuthStorage.getAppItem('post_login_redirect_url') || '/';

            this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
            return;
        }

        // #1 Wait for the discovery document to be loaded,
        //    then parse code, fetch tokens and user profile.
        //
        //    Also listen for errors and show them to user.

        this.oAuthService.events
            .pipe(
                filter(event => event.type === 'discovery_document_loaded'),
                take(1)
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                // After discovery document is loaded, asynchronously parse code from url and fetch tokens
                this.oAuthService.tryLogin();
            });

        this.oAuthService.events
            .pipe(
                filter(event => event instanceof OAuthErrorEvent)
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: OAuthErrorEvent) => {
                this.errors = [
                    ...this.errors,
                    `${event.type} (${(event.reason as any)?.error?.error || '-'})`
                ];

                // FYI: AI logging is handled in app.component
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
