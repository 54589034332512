import { RelationshipReference } from '../../core/models/relationship-reference.model';
import { ResponseNode } from '../../core/models/response-node.model';
import { Deserializable } from './deserializable.model';

export class Catalog implements Deserializable {
  id: string;
  attributes: {
    name: string;
    weight: number;
  };
  relationships: {
    parent: {
      data: Array<RelationshipReference>;
    };
  };
  parent: {
    id: string;
  };
  hasParent: boolean;

  deserialize(input: object, included: Map<string, ResponseNode>): this {
    Object.assign(this, input);

    const [parent] = this.relationships.parent.data;

    this.parent = parent;
    this.hasParent = included.has(parent.id);

    return this;
  }
}
