import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TagGroup } from 'src/app/modules/shared/models/tag-group';
import { Tag } from 'src/app/modules/shared/models/tag.model';
import { FilterGroup } from '../filter/filter-group';
import { FilterItem } from '../filter/filter-item';

@Component({
    selector: 'app-catalog-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent implements OnInit, OnChanges {
    @Input() tags: Tag[] = [];
    @Input() selectableTags: Tag[] = [];
    @Input() selectedTags: Tag[] = [];
    @Input() tagGroups: TagGroup[] = [];

    // build this internally
    filterGroups: FilterGroup[] = [];

    @Output() emitSelectedTags: EventEmitter<Tag[]> = new EventEmitter();

    constructor() { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tagGroups || changes.selectedTags || changes.selectableTags) {
            this.updateFilterGroups();
        }
    }

    private updateFilterGroups() {
        this.filterGroups = this.tagGroups.map((tg) => {
            const filterGroup: FilterGroup = {
                name: tg.name,
                filterItems: tg.tags.map((tid) => {
                    // find matching tag
                    const tag = this.tags.find((t) => t.id === tid);

                    const filterItem: FilterItem = {
                        id: Number(tag.id),
                        name: tag.attributes.name,
                        selected: this.isTagSelected(tag.id),
                        selectable: this.isTagSelectable(tag.id),
                    };

                    return filterItem;
                }),
                visible: false
            };

            filterGroup.visible = !!filterGroup.filterItems.find(item => item.selected || item.selectable);
            filterGroup.filterItems = filterGroup.filterItems.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            return filterGroup;
        });
    }

    private isTagSelectable(tagId: string) {
        return this.selectableTags.find((t) => t.id === tagId) !== undefined;
    }

    private isTagSelected(tagId: string) {
        return this.selectedTags.find((t) => t.id === tagId) !== undefined;
    }

    public selectTag(filterItem: FilterItem) {
        // get tagId
        const tagId = filterItem.id.toString();

        // ignore if not selectable (but exclude selected)
        if (!this.isTagSelectable(tagId) && !this.isTagSelected(tagId)) {
            return;
        }

        const newSelectedTags: Tag[] = [...this.selectedTags];

        if (this.isTagSelected(tagId)) {
            const index = newSelectedTags.findIndex((t) => t.id === tagId);
            newSelectedTags.splice(index, 1);
        } else {
            const tag = this.getTag(tagId);
            newSelectedTags.push(tag);
        }

        this.emitSelectedTags.emit(newSelectedTags);
    }

    private getTag(tagId: string) {
        const tag = this.tags.find((t) => t.id === tagId);
        return tag;
    }
}
