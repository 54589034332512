import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CardModule, ListModule, BottomSheetModule, BottomNavModule, HpoIconModule, ContentLayoutModule, FullScreenDialogModule, PlaceholderModule } from '@headpower/components';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { CatalogSupplierComponent } from './components/catalog-supplier/catalog-supplier.component';
import { CatalogHeadpowerComponent } from './/components/catalog-headpower/catalog-headpower.component';
import { SearchComponent } from './components/search/search.component';
import { CatalogContentComponent } from './components/catalog-content/catalog-content.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { TagListComponent } from './components/tag-list/tag-list.component';
import { FilterComponent } from './components/filter/filter.component';
import { CatalogSearchComponent } from './components/catalog-search/catalog-search.component';
import { TagDialogComponent } from './components/tag-dialog/tag-dialog.component';
import { ProductDialogComponent } from './components/product-dialog/product-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { ChangelogDialogComponent } from './../shared/components/changelog-dialog/changelog-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        CatalogSupplierComponent,
        CatalogHeadpowerComponent,
        SearchComponent,
        CatalogContentComponent,
        ProductListComponent,
        TagListComponent,
        FilterComponent,
        CatalogSearchComponent,
        TagDialogComponent,
        ProductDialogComponent,
        ChangelogComponent,
        ChangelogDialogComponent
    ],
    imports: [
        SharedModule,
        FormsModule,
        CardModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        ListModule,
        BottomNavModule,
        BottomSheetModule,
        HpoIconModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        ContentLayoutModule,
        FullScreenDialogModule,
        MatCheckboxModule,
        PlaceholderModule,
        MatAutocompleteModule,
        MatDialogModule
    ]
})
export class CatalogModule { }