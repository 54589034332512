<div class="instruction-item"
    [class.instruction-item__highlighted]="showCollapseControls">

    <div [id]="id"
        class="instruction-item__header">

        <hpo-placeholder *ngIf="thumbnail && !print && thumbnailLoading"
            type="row"
            class="instruction-item__thumbnail"></hpo-placeholder>

        <img *ngIf="thumbnail && !print && !thumbnailLoading"
            [src]="thumbnailSource"
            class="instruction-item__thumbnail"
            alt="Thumbnail">

        <div class="instruction-item__title-container">

            <h1 class="instruction-item__title">

                {{label}}

                <a routerLink="."
                    [fragment]="id"
                    (click)="scrollToFragmentFromLink()">
                    <mat-icon matTooltip="{{ 'default.copyLink' | blah }}"
                        matTooltipClass="hpo">link</mat-icon>
                </a>

                <a (click)="printItem()">
                    <mat-icon>print</mat-icon>
                </a>

            </h1>

            <a *ngIf="showCollapseControls && collapsed"
                class="instruction-item__symbol"
                (click)="toggleCollapse()">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </a>

            <a *ngIf="showCollapseControls && !collapsed"
                class="instruction-item__symbol"
                (click)="toggleCollapse()">
                <mat-icon>close</mat-icon>
            </a>

        </div>

    </div>

    <div class="instruction-item__paragraph-container"
        [hidden]="collapsed">
        <ng-container appHost></ng-container>
    </div>

    <a *ngIf="showCollapseControls"
        class="instruction-item__toggler"
        [class.instruction-item__toggler--with-icon]="thumbnail && collapsed"
        (click)="toggleCollapse()">
        {{ (collapsed ? 'default.expand' : 'default.close') | blah }}
    </a>

</div>