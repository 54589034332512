import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TagService } from '../../../core/services/tag/tag.service';
import { Instruction } from '../../../shared/models/instruction.model';
import { Tag } from '../../../shared/models/tag.model';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-instructions-for-tag',
  templateUrl: './instructions-for-tag.component.html',
  styleUrls: ['./instructions-for-tag.component.scss'],
})
export class InstructionsForTagComponent implements OnInit, OnDestroy {
  private routeParameterSubscription: Subscription;
  tag: Tag;
  instructions: Array<Instruction>;
  token: string;
  constructor(private tags: TagService, private route: ActivatedRoute, private oAuthStorage: OAuthStorage) {
    this.handleRouteParameterChange = this.handleRouteParameterChange.bind(this);
    this.handleTagResponse = this.handleTagResponse.bind(this);
    this.handleInstructionResponse = this.handleInstructionResponse.bind(this);
    this.token = '?accessToken' + this.oAuthStorage.getItem('access_token');
  }

  ngOnInit() {
    this.routeParameterSubscription = this.route.params.subscribe(this.handleRouteParameterChange);
  }

  ngOnDestroy() {
    if (this.routeParameterSubscription) {
      this.routeParameterSubscription.unsubscribe();
    }
  }

  private handleRouteParameterChange(parameters: { id: string }) {
    this.tags.getOne(parameters.id).subscribe(this.handleTagResponse);
    this.tags.getInstructions(parameters.id).subscribe(this.handleInstructionResponse);
  }

  private handleTagResponse(tag: Tag) {
    this.tag = tag;
  }

  private handleInstructionResponse(instructions: Array<Instruction>) {
    this.instructions = instructions;
  }
}
