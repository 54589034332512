import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { PermissionCategory } from '../../../shared/models/permission-category.model';
import { createPermissionCategoryObjects } from '../../operators/create-permission-category-objects.operator';
import { mapCollectionProperties } from '../../operators/map-collection-properties.operator';

@Injectable()
export class PermissionCategoryService {
  private readonly permissionCategoriesBaseUrl: string
    = `${environment.instructionApiBaseUri}jsonapi/permission_category/permission_category`;

  constructor(private http: HttpClient) {
  }

  getAll(accessibleProducts: Array<string> = []): Observable<Array<PermissionCategory>> {
    const filters = [
      'filter[product_id][operator]=IN',
      ...accessibleProducts.map((id: string): string => `filter[product_id][value][]=${id}`),
    ];

    return this.http
      .get(`${this.permissionCategoriesBaseUrl}?${filters.join('&')}`)
      .pipe(mapCollectionProperties, createPermissionCategoryObjects);
  }
}
