import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EmptyError } from 'rxjs';

import { ParagraphComponent } from '../../../models/paragraph-component/paragraph-component';
import { ResourceEntry, ResourceService } from 'src/app/modules/shared/services/resource.service';

@Component({
    selector: 'app-text-paragraph',
    templateUrl: './text-paragraph.component.html',
    styleUrls: ['./text-paragraph.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TextParagraphComponent implements OnInit, ParagraphComponent {

    @Input() public data: any;

    public loading: boolean = true;

    public sanitizedHtml: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        private resourceService: ResourceService) { }

    ngOnInit() {
        const processedFieldText = this.data.self.attributes.field_text.processed;
        const doc = new DOMParser().parseFromString(processedFieldText, 'text/html');

        this.setLinkTargets(doc);

        // Create align divs for images
        this.wrapDiv(doc, 'align-left');
        this.wrapDiv(doc, 'align-center');
        this.wrapDiv(doc, 'align-right');

        Promise.allSettled([
            this.buildImages(doc)
        ])
            .then(() => {
                this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(doc.documentElement.innerHTML);
                this.loading = false;
            });
    }

    private setLinkTargets(doc: Document) {
        const links = doc.querySelectorAll('a');

        links.forEach(link => {
            if (!link.target) {
                link.setAttribute('target', '_blank');
            }
        });
    }

    /**
     * Creates a parent div for a figure that includes a image.
     * This allows the usage of aligning images using inline-block and text-align.
     * @param doc current html
     * @param className where the image should be aligned to
     */
    private wrapDiv(doc: Document, className: string) {
        const elems = doc.querySelectorAll(`.${className}`);

        elems.forEach(elem => {
            const div = doc.createElement('div');
            div.classList.add(className);

            elem.parentElement.replaceChild(div, elem);
            div.appendChild(elem);
        });
    }

    private async buildImages(doc: Document) {
        const imgElems = doc.querySelectorAll('img');

        if (imgElems.length === 0) {
            return;
        }

        let operations: Promise<void>[] = [];

        imgElems.forEach(elem => {
            operations.push(this.fetchImage(elem));
        });

        await Promise.allSettled(operations);
    }

    private async fetchImage(elem: HTMLImageElement) {
        if (this.resourceService.isPrivateResource(elem.src)) {
            let resource: ResourceEntry;

            try {
                resource = await this.resourceService.getResource('image', elem.src);
            }
            catch (error) {
                // EmptyError is thrown if service disposes the resources while request is in progress.
                //
                // This happens when user navigates away from the instruction while downloading,
                // so just ignore the error and return.
                if (error instanceof EmptyError) {
                    return;
                }

                // Otherwise ignore error and continue execution
            }

            const url = resource?.objectUrl ?? '';

            // FYI: No need to sanitize url here as we are modifying element directly
            elem.src = url;
        }
        // Public resource, no access token header needed
        else {
            elem.src = this.resourceService.buildResourceUrl('image', elem.src);
        }
    }
}
