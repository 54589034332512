<h1 *ngIf="!mobile"
    mat-dialog-title>
    {{ 'default.printInstruction' | blah }}
</h1>

<mat-dialog-content *ngIf="!mobile; else content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-dialog-content>

<ng-template #content>

    <p class="description">
        {{ 'default.selectBlocksToPrint' | blah }}
    </p>

    <div class="checkbox-container">

        <mat-checkbox *ngFor="let item of items"
            id="print-dialog-item-{{item.id}}-checkbox"
            [(ngModel)]="item.selected">
            {{item.title}}
        </mat-checkbox>

    </div>

</ng-template>

<mat-dialog-actions *ngIf="!mobile"
    align="end">

    <button id="print-dialog-cancel-button"
        mat-stroked-button
        mat-dialog-close>
        {{ 'default.cancel' | blah | uppercase }}
    </button>

    <button id="print-dialog-print-button"
        mat-stroked-button
        color="accent"
        (click)="printSelected()">
        {{ 'default.print' | blah | uppercase }}
    </button>

</mat-dialog-actions>