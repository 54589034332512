<mat-form-field style="width: 100%"
    appearance="outline">
    <mat-label>Suodata ohjeen otsikolla</mat-label>
    <input matInput
        placeholder="Suodata ohjeen otsikolla"
        name="keyword"
        [value]="keyword"
        (input)="onSearch($event.target.value)" />
    <mat-icon matPrefix>search</mat-icon>
    <button *ngIf="keyword"
        matSuffix
        mat-icon-button
        (click)="onSearch('')">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>