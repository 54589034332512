<hpo-layout *ngIf="isLoggedIn"
    [backendBaseUri]="backendBaseUri"
    [claims]="claims"
    [currentUser]="currentUser"
    [environmentVariables]="environmentVariables"
    [floatingActionButtonTemplate]="floatingActionButtonContent"
    [locationUri]="locationUri"
    [menuIsHidden]="hideLayoutMenu"
    [menuItems]="menuItems"
    [accountBaseUri]="accountBaseUri"
    [portalBaseUri]="portalBaseUri"
    [backendPortalUri]="backendPortalUri"
    [productId]="productId"
    [productionMode]="productionMode"
    applicationName="IWI"
    applicationNameShort="IWI"
    [appVersion]="appVersion"
    (logoutClicked)="logOut()">
    <ng-template #floatingActionButtonContent>
        <hpo-floating-action-button id="app-bar-fab"
            [mobile]="mobile"></hpo-floating-action-button>
    </ng-template>
</hpo-layout>

<div *ngIf="!isLoggedIn">
    <router-outlet></router-outlet>
</div>