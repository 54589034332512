import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BlahService } from '@headpower/blah-ng';
import { LayoutBreakpointService, ToasterService } from '@headpower/layout';
import { EmptyError, Subject, takeUntil } from 'rxjs';

import { InstructionService } from 'src/app/modules/core/services/instruction/instruction.service';
import { ResourceEntry, ResourceService } from 'src/app/modules/shared/services/resource.service';

@Component({
    selector: 'app-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss'],
    providers: [ResourceService]
})
export class FileComponent implements OnInit, OnDestroy {

    public mobile: boolean = false;
    public initializing: boolean = true;
    public loadingFile: boolean = false;

    public alias: string = '';

    public fileName: string = '';
    public filePath: string = '';

    private destroy$: Subject<void> = new Subject();

    constructor(
        private layoutBreakpointService: LayoutBreakpointService,
        private route: ActivatedRoute,
        private instructionService: InstructionService,
        private resourceService: ResourceService,
        private toaster: ToasterService,
        private blahService: BlahService) { }

    ngOnInit() {
        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                this.mobile = result.handset;
            });

        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                this.initFile(params);
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public async downloadFile() {
        if (this.loadingFile) {
            return;
        }

        this.loadingFile = true;

        let resource: ResourceEntry;

        try {
            resource = await this.resourceService.getResource('file', this.filePath, this.fileName);
        }
        catch (error) {
            this.loadingFile = false;

            // EmptyError is thrown if service disposes the resources while request is in progress.
            //
            // This happens when user navigates away from the instruction while downloading,
            // so just ignore the error and return.
            if (error instanceof EmptyError) {
                return;
            }

            this.toaster.create(this.blahService.blah('default.errorWhileDownloadingFile'), {
                color: 'warn'
            });

            console.error(error);
            return;
        }

        this.loadingFile = false;

        const link = document.createElement('a');

        link.href = resource.objectUrl;
        link.download = this.fileName;

        link.dispatchEvent(new MouseEvent('click'));
    }

    private initFile(params: Params) {
        this.initializing = true;

        // Dispose resources if changing from previous file
        if (this.alias) {
            this.resourceService.disposeAllResources();
        }

        this.alias = params.alias;
        this.fileName = params.name;
        this.filePath = params.path;

        this.instructionService.checkInstructionUsageRight(this.alias)
            .subscribe(() => {
                this.initializing = false;
            });
    }
}
