<div class="checklist-paragraph">

    <hpo-placeholder *ngIf="loading"
        type="row"></hpo-placeholder>

    <ng-container *ngIf="!loading">

        <h2 *ngIf="data.self.attributes.field_title"
            class="checklist-paragraph__title">
            {{data.self.attributes.field_title}}
        </h2>

        <div class="checklist-paragraph__content"
            [innerHTML]="sanitizedHtml"></div>

    </ng-container>

</div>