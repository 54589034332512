<ul class="file-list">

    <li *ngFor="let file of files; let i = index">

        <div class="file-list__item-container">

            <mat-icon [class]="file.icon">
                {{file.icon}}
            </mat-icon>

            <a href="file/{{aliasEncoded}}/{{file.pathEncoded}}/{{file.nameEncoded}}"
                target="_blank"
                class="accent"
                (click)="downloadFile(file, i); false">
                {{file.name}}
            </a>

        </div>

        <!-- FYI: Must be after the link to have larger stacking index -->
        <mat-progress-bar *ngIf="loadingFile === i"
            mode="indeterminate"></mat-progress-bar>

    </li>

</ul>