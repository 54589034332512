import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Category } from '../../../shared/models/category.model';
import { createCategoryObjects } from '../../operators/create-category-objects.operator';
import { mapCollectionProperties } from '../../operators/map-collection-properties.operator';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class CategoryService {
  private readonly categoriesBaseUrl: string = `${environment.instructionApiBaseUri}jsonapi/taxonomy_term/category`;
  private readonly defaultHeaders: { [header: string]: string } = {};

  constructor(private http: HttpClient, private oAuthService: OAuthService) {
    this.defaultHeaders = {
      Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
    };
  }

  public getAll(): Observable<Array<Category>> {
    const include = [
      'field_icon',
    ];

    return this.http
      .get(`${this.categoriesBaseUrl}?include=${include.join(',')}`,
        {
          headers: this.defaultHeaders,
          withCredentials: true,
        })
      .pipe(mapCollectionProperties, createCategoryObjects);
  }
}
