import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LayoutBreakpointService } from '@headpower/layout';
import { EmptyError, Subject, takeUntil } from 'rxjs';
import { IAlbum, Lightbox } from 'ngx-lightbox';

import { ParagraphComponent } from '../../../models/paragraph-component/paragraph-component';
import { ResourceEntry, ResourceService } from 'src/app/modules/shared/services/resource.service';

/**
 * This component might be named image, but it covers the image paragraph, which can contain many types of media.
 */
@Component({
    selector: 'app-image-paragraph',
    templateUrl: './image-paragraph.component.html',
    styleUrls: ['./image-paragraph.component.scss']
})
export class ImageParagraphComponent implements OnInit, OnDestroy, ParagraphComponent {

    @Input() public data: any;

    public mobile: boolean = false;
    public loading: boolean = false;

    public source: SafeResourceUrl;
    public caption: string;
    public type: string;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private lightbox: Lightbox,
        private sanitizer: DomSanitizer,
        private layoutBreakpointService: LayoutBreakpointService,
        private resourceService: ResourceService) { }

    ngOnInit() {
        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                this.mobile = result.handset;
            });

        this.resolveMediaType();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public openLightbox() {
        const album: IAlbum = {
            src: this.source as any,
            thumb: null,
            caption: this.caption
        };

        this.lightbox.open([album], 0, {
            centerVertically: true
        });
    }

    private resolveMediaType() {
        const field = this.data.full.includedMap.get(this.data.self.relationships.field_image.data.id);

        if (!field) {
            return;
        }

        this.type = field.type;
        this.caption = this.data.self.attributes.field_caption;

        switch (this.type) {
            case 'media--image':
                this.setSourceFromImage(field);
                break;

            case 'media--video':
                this.setSourceFromVideo(field);
                break;
        }
    }

    private async setSourceFromImage(field: any) {
        const file = this.data.full.includedMap.get(field.relationships.field_media_image.data.id);

        if (!file) {
            return;
        }

        const imageUrl = file.attributes.uri.url as string;

        if (this.resourceService.isPrivateResource(imageUrl)) {
            this.loading = true;

            let resource: ResourceEntry;

            try {
                resource = await this.resourceService.getResource('image', imageUrl, file.attributes.filename);
            }
            catch (error) {
                // EmptyError is thrown if service disposes the resources while request is in progress.
                //
                // This happens when user navigates away from the instruction while downloading,
                // so just ignore the error and return.
                if (error instanceof EmptyError) {
                    return;
                }

                // Otherwise ignore error and continue execution
            }

            const url = resource?.objectUrl ?? '';

            this.source = this.sanitizer.bypassSecurityTrustResourceUrl(url);

            this.loading = false;
        }
        // Public resource, no access token header needed
        else {
            const url = this.resourceService.buildResourceUrl('image', imageUrl);

            this.source = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    }

    private setSourceFromVideo(field: any) {
        const id = field.attributes.field_media_video_embed_field.split('/').pop();
        const params = ['autoplay=0', 'title=0', 'byline=0', 'portrait=0'];
        const url = `https://player.vimeo.com/video/${id}?${params.join('&')}`;

        this.source = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
