<div *ngIf="!mobile"
    class="dialog--header">{{ 'default.changelogTitle' | blah }}</div>
<mat-dialog-content class="changelog-dialog-content">
    <app-changelog [changelogEntriesInput]="changelogEntries"
        [mobile]="mobile"></app-changelog>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!mobile"
    align="end">
    <button mat-button
        mat-dialog-close>{{ 'default.close' | blah | uppercase }}</button>
</mat-dialog-actions>