import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { LayoutBreakpointService } from '@headpower/layout';

@Component({
    templateUrl: 'no-usage-right.component.html',
    styleUrls: ['no-usage-right.component.scss'],
})
export class NoUsageRightComponent implements OnDestroy {

    public characterName: string;
    public searchActive = false;
    public mobile = false;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private router: Router,
        private layoutBreakpointService: LayoutBreakpointService,
    ) {
        Math.round(Math.random())
            ? this.characterName = 'hemmo'
            : this.characterName = 'helmi';

        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.mobile = result.handset);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public setSearchActive(): void {
        this.searchActive = true;
    }

    public browseInstructions(): void {
        this.router.navigateByUrl('/catalog');
    }
}
