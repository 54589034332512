import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { InstructionService } from 'src/app/modules/core/services/instruction/instruction.service';

@Component({
    selector: 'app-instruction-by-uuid',
    templateUrl: './instruction-by-uuid.component.html',
    styleUrls: ['./instruction-by-uuid.component.scss']
})
export class InstructionByUuidComponent implements OnInit, OnDestroy {

    @Output() instructionEmitter: EventEmitter<any> = new EventEmitter();

    private destroy$: Subject<void> = new Subject();

    constructor(
        private instructions: InstructionService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                this.handleRouteParameterChange(params);
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private handleRouteParameterChange(params: Params) {
        this.instructionEmitter.emit(undefined);

        this.instructions.getOne(params.id)
            .subscribe(result => {
                this.instructionEmitter.emit(result);
            });
    }
}
