import { environment } from '../../../../environments/environment';
import { ResponseNode } from '../../core/models/response-node.model';
import { Deserializable } from './deserializable.model';

export class Resource implements Deserializable {
  id: string;
  attributes: {
    uri: {
      url: string;
    };
  };
  url: string;

  deserialize(input: object, included?: Map<string, ResponseNode>): this {
    Object.assign(this, input);

    this.url = `${environment.instructionApiBaseUri}${this.attributes.uri.url}`;

    return this;
  }
}
