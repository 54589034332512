import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Instruction } from '../../../shared/models/instruction.model';
import { Tag } from '../../../shared/models/tag.model';
import { createQueryString } from '../../helpers/create-query-string.helper';
import { MappedSingletonResponse } from '../../models/mapped-singleton-response.model';
import { createInstructionObjects } from '../../operators/create-instruction-objects.operator';
import { mapCollectionProperties } from '../../operators/map-collection-properties.operator';
import { mapSingletonProperties } from '../../operators/map-singleton-properties.operator';

@Injectable()
export class TagService {
  private readonly tagsBaseUrl: string = `${environment.instructionApiBaseUri}jsonapi/taxonomy_term/tags`;
  private readonly instructionsBaseUrl: string = `${environment.instructionApiBaseUri}jsonapi/instruction/instruction`;

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http
      .get(this.tagsBaseUrl);
  }

  getOne(id: string): Observable<Tag> {
    const include = [
      'instructions.field_category.field_icon',
    ];

    return this.http
      .get(`${this.tagsBaseUrl}/${id}?include=${include.join(',')}`)
      .pipe(
        mapSingletonProperties,
        map((response: MappedSingletonResponse): Tag => new Tag().deserialize(response.data)),
      );
  }

  getInstructions(id: string): Observable<Array<Instruction>> {
    const include = [
      'field_category.field_icon',
    ];
    const filters = createQueryString({
      'filter[field_tags.id]': id,
    });

    return this.http
      .get(`${this.instructionsBaseUrl}?include=${include.join(',')}&${filters}`)
      .pipe(mapCollectionProperties, createInstructionObjects);
  }
}
