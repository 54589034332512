import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-search',
  templateUrl: './add-search.component.html',
  styleUrls: ['./add-search.component.scss'],
})
export class AddSearchComponent implements AfterViewInit {
  @Input() active: boolean;
  @Output() activate = new EventEmitter<boolean>();

  ngAfterViewInit() {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://addsearch.com/js/?key=a7311bc14341503544ed9be203f57872';

    document.querySelector('body').appendChild(script);
  }
}
