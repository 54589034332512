import { map } from 'rxjs/operators';
import { mapProperty } from '../helpers/map-property.helper';
import { CollectionResponse } from '../models/collection-response.model';
import { MappedCollectionResponse } from '../models/mapped-collection-response.model';

export const mapCollectionProperties = map((response: CollectionResponse): MappedCollectionResponse => ({
  ...response,
  dataMap: mapProperty(response.data),
  includedMap: mapProperty(response.included),
}));
