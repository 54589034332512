import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

/** Add ngsw-bypass to headers and pass request through to the next request handler. */
@Injectable()
export class BypassServiceWorkerInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Clone the request and replace the original headers with
        // cloned headers, updated with ngsw-bypass.
        const bypassReq = req.clone({
            headers: req.headers.set('ngsw-bypass', '')
        });

        // send cloned request with header to the next handler.
        return next.handle(bypassReq);
    }

}
