<div class="instruction-list-paragraph">

    <h2 *ngIf="data.self.attributes.field_instruction_list_title"
        class="instruction-list-paragraph__title">
        {{data.self.attributes.field_instruction_list_title}}
    </h2>

    <ul *ngIf="tags"
        class="instruction-list-paragraph__items">
        <li *ngFor="let tag of tags">
            <a [routerLink]="tag.path">{{tag.name}}</a>
        </li>
    </ul>

</div>