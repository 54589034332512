import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { PortalProduct } from './portal-product';

@Injectable()
export class PortalService {
  private readonly apiUrl = `${environment.backendBaseUri}/Portal.BackEnd/WebAPI/api/Product/GetLicencedApplications`;
  private readonly defaultHeaders: { [header: string]: string } = {};

  constructor(private http: HttpClient, private oAuthService: OAuthService) {
    this.defaultHeaders = {
      Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
    };
  }
  getApplications(): Observable<Array<PortalProduct>> {
    return this.http
      .get<Array<PortalProduct>>(this.apiUrl, {
        headers: this.defaultHeaders,
        withCredentials: true,
      })
      .pipe(
        map((products) =>
          products
            .filter((product) => product.IsInstructionsProduct)
            .sort((a, b) => {
              // first order by group name
              if (
                a.AccessGroupName.toLowerCase() !==
                b.AccessGroupName.toLowerCase()
              ) {
                return a.AccessName.toLowerCase().localeCompare(
                  b.AccessName.toLowerCase(),
                );
              }
              // then by product name
              return a.AccessName.toLowerCase().localeCompare(
                b.AccessName.toLowerCase(),
              );
            }),
        ),
      );
  }
}
