<div class="dialog">
  <app-catalog-tag-list
    [tags]="this.data.tags"
    [tagGroups]="this.data.tagGroups"
    [selectedTags]="this.data.selectedTags"
    [selectableTags]="this.data.selectableTags"
    (emitSelectedTags)="selectTags($event)"
  >
  </app-catalog-tag-list>
</div>
