import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, takeUntil } from 'rxjs';

import { InstructionPrintItem, InstructionPrintDialogData, InstructionPrintDialogResultData } from './print-dialog.model';

interface SelectableInstructionPrintItem extends InstructionPrintItem {
    selected: boolean;
}

@Component({
    selector: 'app-instruction-print-dialog',
    templateUrl: './print-dialog.component.html',
    styleUrls: ['./print-dialog.component.scss']
})
export class InstructionPrintDialogComponent implements OnInit, OnDestroy {

    public mobile: boolean = false;
    public items: SelectableInstructionPrintItem[] = [];

    private destroy$: Subject<void> = new Subject();

    constructor(
        private dialogRef: MatDialogRef<InstructionPrintDialogComponent, InstructionPrintDialogResultData>,
        @Inject(MAT_DIALOG_DATA) private dialogData: InstructionPrintDialogData) { }

    ngOnInit() {
        this.mobile = this.dialogData.mobile;

        this.items = this.dialogData.items
            .map(o => ({
                ...o,
                selected: true
            }) as SelectableInstructionPrintItem);

        if (this.mobile) {
            const dialogAction = (this.dialogData as any).actionClicked as Observable<void>;

            dialogAction
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.printSelected();
                });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public printSelected() {
        const selectedItems = this.items
            .filter(o => o.selected)
            .map(o => ({
                id: o.id,
                title: o.title
            }) as InstructionPrintItem);

        this.dialogRef.close({
            selectedItems
        });
    }
}
