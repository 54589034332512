import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { CanComponentDeactivate } from '@headpower/layout';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppOAuthStorage } from '@headpower/angular-oauth2-oidc-extensions';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanDeactivate<CanComponentDeactivate> {

    private hasUsageRight: boolean = false;
    private hasUsageRightCached: boolean = false;

    // Filtered query params
    private filteredParams: string[] = [
        'authTicket'
    ];

    constructor(
        private oAuthService: OAuthService,
        private oAuthStorage: AppOAuthStorage,
        private router: Router) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const redirectUrl = new URL(state.url, window.location.origin);

        let filteringNeeded = false;
        this.filteredParams.forEach(p => {
            if (redirectUrl.searchParams.has(p)) {
                filteringNeeded = true;
                redirectUrl.searchParams.delete(p);
            }
        });

        // Check if authenticated (has id token)
        if (this.oAuthService.hasValidIdToken()) {
            // If usage right is cached use the cached value
            if (this.hasUsageRightCached) {
                // Has usage right
                if (this.hasUsageRight) {
                    // If filtering is needed redirect user to filtered url
                    if (filteringNeeded) {
                        this.navigateByUrl(redirectUrl);
                        return false;
                    }

                    return true;
                }

                // No usage right
                this.router.navigate(['/no-usage-right']);
                return false;
            }

            // FYI: Placeholder for logic if usage right is not cached and need to check it from service

            // Cache result only if user has usage right
            // so missing usage right can be given to user while logged in.
            this.hasUsageRight = true;
            this.hasUsageRightCached = true;

            // If filtering is needed redirect user to filtered url
            if (filteringNeeded) {
                this.navigateByUrl(redirectUrl);
                return false;
            }

            return true;
        }

        // Reset usage right cache before login flow
        this.hasUsageRight = false;
        this.hasUsageRightCached = false;

        // Set post login redirect url for redirecting user back after login flow is finished
        this.oAuthStorage.setAppItem('post_login_redirect_url', redirectUrl.pathname + redirectUrl.search + redirectUrl.hash);

        // FYI: Ticket authentication is disabled for now.
        //      Might be removed from the code in the future.
        /*
        // If route has auth ticket parameter set it as acr_values property
        if (route.queryParamMap.has('authTicket')) {
            const authTicket = route.queryParamMap.get('authTicket');

            this.oAuthService.customQueryParams = {
                acr_values: 'authTicket:' + authTicket
            };
        }
        */

        this.oAuthService.initLoginFlow();

        return false;
    }

    canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return component.canDeactivate ? component.canDeactivate() : true;
    }

    private navigateByUrl(url: URL) {
        this.router.navigateByUrl(url.pathname + url.search + url.hash);
    }
}
