<ng-template [ngIf]="!loading"
    [ngIfElse]="placeholders">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #content>
    <ng-template [ngIf]="!requireProduct || (requireProduct && selectedProductId)"
        [ngIfElse]="noProduct">
        <div class="productName__container">
            <span *ngIf="productName">{{ productName }}</span>
            <a class="changelog"
                (click)="showChangelog($event)"
                *ngIf="changelogEntries && changelogEntries.length > 0">
                {{ 'default.changelogProduct' | blah }}
            </a>
        </div>

        <div *ngIf="prefix === 'supplier'"
            class="guide">
            {{ 'default.catalogGuide' | blah }}
        </div>

        <div *ngIf="viewCatalogs && viewCatalogs.length"
            class="result-count-container">
            <p>Näytetään {{ resultCount }} ohjetta</p>
            <a class="changelog"
                routerLink="/changelog"
                *ngIf="!selectedProductId">
                {{ 'default.toChangelog' | blah }}
            </a>
        </div>

        <ng-template [ngIf]="view && view.length"
            [ngIfElse]="noInstructions">
            <div class="wrapper">
                <div class="wrapper__content">
                    <hpo-list>
                        <ng-template ngFor
                            let-instruction
                            [ngForOf]="view">
                            <hpo-list-item [ngClass]="{'lastClickedItem': lastClickedId === instruction.attributes.path.alias}">
                                <div class="catalogue-item">
                                    <div class="icon">
                                        <img *ngIf="instruction.icon"
                                            [src]="instruction.icon.url"
                                            alt="Instruction icon" />
                                        <mat-icon *ngIf="!instruction.icon">article</mat-icon>
                                    </div>
                                    <div class="title">
                                        <div>
                                            <a routerLink="{{ instruction.attributes.path.alias }}"
                                                (click)="handleSaveScroll(instruction.attributes.path.alias)"
                                                [id]="instruction.attributes.path.alias">
                                                <span [ngClass]="{'lastClickedId': lastClickedId === instruction.attributes.path.alias}">
                                                    {{ instruction.attributes.name }}</span></a>
                                        </div>
                                        <div class="title--category">
                                            <span *ngIf="instruction.category"
                                                class="title--category--text">{{ instruction.category }}</span>
                                            <div *ngFor="let icon of instruction.categoryIcons">
                                                <hpo-icon color="{{ icon }}">{{ icon }}</hpo-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </hpo-list-item>
                        </ng-template>
                    </hpo-list>
                </div>
            </div>
        </ng-template>
    </ng-template>
</ng-template>

<ng-template #placeholders>
    <div class="catalog__placeholders">
        <hpo-placeholder type="row"></hpo-placeholder>
        <hpo-placeholder type="row"></hpo-placeholder>
        <hpo-placeholder type="row"></hpo-placeholder>
    </div>
</ng-template>

<ng-template #noInstructions>
    <ng-container *ngIf="!loading">
        <h2 class="catalog__backup-title">{{ 'default.noInstructions' | blah }}</h2>
    </ng-container>
</ng-template>

<ng-template #noProduct>
    <ng-container *ngIf="!loading">
        <h2 class="catalog__backup-title">{{ 'default.noProduct' | blah }}</h2>
    </ng-container>
</ng-template>