import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupPriority, PortalProduct } from 'src/app/modules/core/services/portal/portal-product';
import { FilterItem } from '../filter/filter-item';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnChanges {
    @Input() stacked = false;
    @Input() products: PortalProduct[] = [];
    @Input() selectedProductId: number;
    @Input() GroupPriority: GroupPriority;
    @Input() catalogView = false;

    @Output() emitProductSelect: EventEmitter<PortalProduct | undefined> =
        new EventEmitter();

    public filterItems: FilterItem[] = [];
    selectedCompany = new FormControl('');
    filteredOptions: Observable<any>;

    constructor(private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        this.filteredOptions = this.selectedCompany.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || ''))
        );
    }

    displayFn(item: FilterItem): string {
        return item && item.name ? item.name : '';
    }

    private _filter(value) {
        const filterValue = value && value.name ? value.name.toLowerCase() : value.toLowerCase();

        return this.filterItems.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    handleEmptyInput(event) {
        if (event.target.value === '') {
            this.emitProductSelect.emit(undefined);
        }
    }

    emptySearch() {
        let a: any;
        a = this.selectedCompany.value['id'] ? this.selectedCompany.value : { selected: true };
        this.selectProduct(a);

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.GroupPriority || changes.selectedProductId) {
            this.filterItems = this.products
                .filter((p) => p.GroupPriority === this.GroupPriority)
                .map((p) => {
                    const filterItem: FilterItem = {
                        id: p.Access_UID,
                        name: p.AccessName,
                        selectable: true,
                        selected: p.Access_UID === this.selectedProductId,
                    };
                    return filterItem;
                });

            if (this.selectedProductId) {
                let a: any = this.filterItems.find(item => item.id === this.selectedProductId);
                this.selectedCompany.setValue(a)
            }
        }
    }

    public selectProduct(filterItem: FilterItem) {
        const { queryParams } = this.route.snapshot;

        // delegate to upper level
        if (filterItem.selected) {
            this.emitProductSelect.emit(undefined);
            this.selectedCompany.setValue('');

            // change url
            this.router.navigate([], {
                queryParams: { ...queryParams, productId: undefined },
            });
        } else {
            const product = this.products.find((p) => p.Access_UID === filterItem.id);
            this.emitProductSelect.emit(product);

            // change url
            this.router.navigate([], {
                queryParams: { ...queryParams, productId: filterItem.id },
            });
        }
    }
}
